import { gql } from '@apollo/client';
import { User } from './entities/user';

export interface Response {
  constructionCompany: {
    users: User[];
  };
}

export interface Variables {
  id: number;
}

export default gql`
  query ($id: Int) {
    constructionCompany(id: $id) {
      users {
        id
        name
        CPF
        CNPJ
        email
        active
        roles
      }
    }
  }
`;
