import { gql } from '@apollo/client';
import { User } from './entities/user';

export interface Response {
  login: {
    token: string;
    user: User;
  };
}

export default gql`
  mutation login($email: String!, $pass: String!) {
    login(authInput: { email: $email, password: $pass }) {
      token
      user {
        id
        name
        CPF
        email
        active
        realEstate {
          id
          name
        }
        updatedAt
        createdAt
        roles {
          name
        }
        constructionCompany {
          id
          name
        }
      }
    }
  }
`;
