export enum Status {
  // COMUNS
  CONSULTACPF = 'CONSULTA DE CPF',
  CPFREST = 'CPF COM RESTRIÇÃO',
  CPFSEMREST = 'CPF SEM RESTRIÇÃO',
  DOCENV = 'DOCUMENTAÇÃO ENVIADA',
  DOCANS = 'ANÁLISE DOCUMENTAÇÃO',
  DOCPEN = 'PENDÊNCIA DOCUMENTAL',

  // FINANCIAMENTO
  BANCANS = 'ANÁLISE BANCÁRIA',
  APROVSICAQ = 'APROVADO SICAQ',
  APROVCOND = 'APROVADO CONDICIONADO',
  BANCANSREP = 'ANÁLISE BANCÁRIA REPROVADA',
  ASSINADO = 'CONTRATO E FORMULÁRIO ASSINADO',

  MNTGDOSSIE = 'MONTAGEM DE DOSSIÊ',
  ENTDOSSIE = 'DOSSIÊ ENTREGUE / AGUARDANDO VALIDAÇÃO',
  AGDCONF = 'AGUARDANDO CONFORMIDADE',
  CONFORME = 'CONFORME',
  INCONFORME = 'INCONFORME',
  AGDOSSAGENCIA = 'AGENDADO ASSINATURA NA AGENCIA',
  ASSAGENCIA = 'ASSINADO NA AGÊNCIA',
  ASSGER = 'AGUARDANDO ASSINATURA GERENCIAL DO BANCO',
  ITBIRGI = 'ITBI & RGI',

  // TERRENO
  ANSPROP = 'ANÁLISE DE PROPOSTA',
  PROPREP = 'PROPOSTA RECUSADA/REPROVADA',
  PROPAPR = 'PROPOSTA APROVADA',
  CONTBOLGER = 'CONTRATO/BOLETO GERADO',

  // FINALIZADO
  FINC = 'FINALIZADO (VENDA CONCLUÍDA)',
  FIND = 'FINALIZADO (DESISTÊNCIA/FALHA)',

  INT = 'INTERNALIZADO/PV',
  DISC = 'EM DISCUSSÃO',

  // AG
  AGNDADO = 'AGENDADO ASSINATURA DE CONTRATO/FORMULÁRIO',

  // SIOPI
  CADSIOPI = 'CADASTRO SIOPI',

  // CONSTRUTORA
  AGUARDCONS = 'AGUARDANDO DOCUMENTAÇÃO DA CONSTRUTORA',

  // ENGENHARIA
  ABENG = 'ABERTURA DE ENGENHARIA',

  // VISTORIA
  AGUARDVIS = 'AGUARDANDO VISTORIA',
  VISTOK = 'VISTORIA CONCLUÍDA / AGUARDANDO CADASTRO DE LAUDO',

  // LAUDO
  LAUDOPEN = 'LAUDO COM PENDÊNCIA',
  LAUDOOK = 'LAUDO OK',

  // CONTRATO CAIXA
  RETCONTRGI = 'RETIRADA DE CONTRATO CAIXA / SOLITAÇÃO DE RGI',
  ENTCONTRGI = 'ENTREGUE CONTRATO CAIXA / RGI OK',

  // CARTORIO
  DOCEMP = 'DOCUMENTAÇÃO DO EMPREENDIMENTO',
  CONSIFC = 'CONSULTA DÍVIDA ATIVA/NÃO ATIVA',
  SOLDP = 'SOLICITAÇÃO INTEIRO TEOR',

  // RENDA INFORMAL
  AGNDENT = 'AGENDAMENTO DE ENTREVISTA',
  AGUAAP = 'AGUARDANDO APURAÇÃO DE RENDA',
  RENDREP = 'RENDA REPROVADA',
  RENDAP = 'RENDA APROVADA',

  // IMÓVEL AVULSO SEM DOC
  CADFAZ = 'CADASTRAMENTO DO IMÓVEL NA SECRETARIA DE FAZENDA',
  ANSFAZ = 'ANÁLISE DA SECRETARIA DA FAZENDA',
  FAZOK = 'SECRETARIA DE FAZENDA OK',
}
