import { gql } from '@apollo/client';

export interface Response {
  teams: {
    id: string;
    name: string;
    owner: {
      id: string;
      name: string;
    };
  }[];
}

export default gql`
  query {
    teams {
      id
      name
      owner {
        id
        name
      }
    }
  }
`;
