import React, { useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { FilePresent, OpenInNew } from '@mui/icons-material';
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
} from '@mui/material';
import { toast } from 'react-toastify';

// Type
import { Document } from '../../graphql/entities/document';

// Mutation
import deleteDocumentMutation from '../../graphql/deleteDocumentMutation';
import updateDocument, { Response } from '../../graphql/updateDocumentMutation';

// import { Container } from './styles';

interface Props {
  documents: Document[];
  permission: boolean;
  setDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
  idDoc: string;
}

const DocumentsTable: React.FC<Props> = ({
  documents,
  permission,
  setDocuments,
  idDoc,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogProtectOpen, setIsDialogProtectOpen] = useState(false);
  const [isDialogDeleteOpen, setIsDialogDeleteOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(0);
  const [loading, setIsLoading] = useState(false);

  const documentsToShow = useMemo(
    () => documents.filter(d => d.name === idDoc),
    [documents, idDoc]
  );

  const [runUpdateDocument] = useMutation<Response>(updateDocument);
  const [runDeleteDocument] = useMutation(deleteDocumentMutation);

  async function approveOrReprove(id: number, approved: boolean) {
    setIsLoading(true);
    try {
      await runUpdateDocument({
        variables: {
          id: Number(id),
          approved,
        },
      });

      setDocuments(
        documents.map(d => {
          if (d.id === id) {
            return { ...d, approved };
          }
          return d;
        })
      );
    } catch (err) {
      toast.error('Não foi possível realizar a ação!');
    }
    setIsDialogOpen(false);
    setIsLoading(false);
  }

  async function protect(id: number, value: boolean) {
    setIsLoading(true);
    try {
      await runUpdateDocument({
        variables: {
          id: Number(id),
          protected: value,
        },
      });

      setDocuments(
        documents.map(d => {
          if (d.id === id) {
            return { ...d, protected: value };
          }
          return d;
        })
      );
    } catch (err) {
      toast.error('Não foi possível realizar a ação!');
    }
    setIsDialogProtectOpen(false);
    setIsLoading(false);
  }

  async function deleteDocument(id: number) {
    setIsLoading(true);
    try {
      await runDeleteDocument({
        variables: {
          id: Number(id),
        },
      });

      setDocuments(documents.filter(d => d.id !== id));
    } catch (err) {
      toast.error('Não foi possível realizar a ação!');
    }
    setIsDialogDeleteOpen(false);
    setIsLoading(false);
  }

  if (documentsToShow.length <= 0) return <></>;

  return (
    <>
      <Grid spacing={2} container sx={{ marginBottom: 1 }}>
        {documentsToShow.map(d => (
          <Grid key={d.key} item xs={12} sm={4} md={2}>
            <Paper sx={{ padding: 1, textAlign: 'center' }}>
              <a
                style={{
                  textDecoration: 'none',
                  color: '#55178F',
                }}
                href={d?.url || ''}
                target="_blank"
                rel="noreferrer"
              >
                <FilePresent style={{ fontSize: '40px' }} />

                <p
                  style={{
                    wordWrap: 'break-word',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {(d?.key && d?.key.split('-')[1]) || ''}
                </p>
                <OpenInNew sx={{ marginX: 1 }} fontSize="small" />
              </a>
              <Grid item xs={12} marginY={1}>
                <Chip
                  sx={{
                    cursor: permission ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (permission) {
                      setCurrentDocument(d?.id || 0);
                      setIsDialogOpen(true);
                    }
                  }}
                  label={
                    d.approved === null
                      ? 'Em análise'
                      : d.approved
                      ? 'Aprovado'
                      : 'Reprovado'
                  }
                  color={
                    d.approved === null
                      ? 'warning'
                      : d.approved
                      ? 'success'
                      : 'error'
                  }
                />
              </Grid>
              {permission && d.name?.toUpperCase() !== 'IMOBZI' && (
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Chip
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setCurrentDocument(d?.id || 0);
                        setIsDialogProtectOpen(true);
                      }}
                      label={d.protected ? 'Protegido' : 'Acesso Liberado'}
                      color={d.protected ? 'info' : 'warning'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Chip
                      sx={{
                        cursor: 'pointer',
                        marginTop: { lg: 0, xs: 1 },
                      }}
                      onClick={() => {
                        setCurrentDocument(d?.id || 0);
                        setIsDialogDeleteOpen(true);
                      }}
                      label={'Deletar'}
                      color={'error'}
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Selecione uma ação</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={2}>
            {permission && (
              <>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => approveOrReprove(currentDocument, true)}
                >
                  Aprovar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => approveOrReprove(currentDocument, false)}
                >
                  Reprovar
                </Button>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDialogProtectOpen}
        onClose={() => setIsDialogProtectOpen(false)}
      >
        <DialogTitle>Selecione uma ação</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="info"
              onClick={() => protect(currentDocument, true)}
            >
              Proteger
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => protect(currentDocument, false)}
            >
              Liberar acesso
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogProtectOpen(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDialogDeleteOpen}
        onClose={() => setIsDialogDeleteOpen(false)}
      >
        <DialogTitle>Excluir arquivo #{currentDocument}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir o arquivo ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogDeleteOpen(false)}>Cancelar</Button>
          <Button onClick={() => deleteDocument(currentDocument)}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{
          color: theme => theme.palette.primary.main,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  );
};

export default DocumentsTable;
