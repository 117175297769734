import { gql } from '@apollo/client';
import { Pac } from './entities/pac/index';
import { Status } from './entities/pac/status.enum';
import { Type } from './entities/pac/type.enum';

export interface Response {
  pacs: {
    pacs: Pac[];
    page: number;
    pacsInPage: number;
    totalPages: number;
    totalPacs: number;
  };
}

export interface Variables {
  getPacsInput?: {
    cpf?: string;
    name?: string;
    page?: number;
    status?: Status;
    type?: Type;
    userId?: string;
    realEstateId?: number;
    createdAt?: {
      begin: string;
      end: string;
    };
    updatedAt?: {
      begin: string;
      end: string;
    };
    teamId?: number;
  };
}

export default gql`
  query pacs($getPacsInput: GetPacsInput!) {
    pacs(getPacsInput: $getPacsInput) {
      pacs {
        id
        nameClient
        type
        CPFClient
        nameConjuge
        CPFConjuge
        product
        status
        user {
          name
          TeamUsers{
            team{
              name
              owner{
                name
              }
            }
          }
          realEstate {
            name
          }
          constructionCompany {
            name
          }
        }
        constructionCompany {
          name
        }
        operator {
          name
        }
        createdAt
        updatedAt
      }
      page
      pacsInPage
      totalPages
      totalPacs
    }
  }
`;
