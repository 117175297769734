import React from 'react';

// Import React FilePond
import { FilePondFile } from 'filepond';
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
// @ts-ignore
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// @ts-ignore
import FilePondPluginSize from 'filepond-plugin-file-validate-size';
// @ts-ignore
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';
import 'filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css';

// Mutations

import { Container } from './styles';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginPdfPreview,
  FilePondPluginFileValidateType,
  FilePondPluginSize
);

interface Props {
  files: FilePondFile[];
  setFiles: React.Dispatch<React.SetStateAction<FilePondFile[]>>;
}

const FileUploadCreate: React.FC<Props> = ({ files, setFiles }) => {
  return (
    <Container>
      <FilePond
        acceptedFileTypes={['image/*', 'application/pdf']}
        files={files as any}
        maxFiles={1}
        onupdatefiles={setFiles}
        allowMultiple={false}
        instantUpload={false}
        name="files"
        maxFileSize={'15MB'}
        labelMaxFileSize={'O tamanho máximo de um arquivo é {filesize}'}
        labelMaxFileSizeExceeded={'O arquivo não pode ser maior que 15MB'}
        allowFileSizeValidation
        labelTapToCancel=""
        allowProcess={false}
        // @ts-ignore
        allowImageCrop
        labelIdle='Arraste os seus arquivos ou <span class="filepond--label-action">Clique</span>'
        labelFileProcessingError="Não foi possível fazer o upload!"
        labelFileProcessing="Fazendo Upload"
        allowRevert={false}
        labelFileProcessingComplete="Upload Completo! "
      />
    </Container>
  );
};

export default FileUploadCreate;
