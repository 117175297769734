import { gql } from '@apollo/client';
import { User } from './entities/user';

export interface Response {
  allRealEstate: {
    id: string;
    name: string;
    active: boolean;
    users: User[];
  }[];
}

export interface Variables {
  findAllRealEstateInput: {
    name?: string;
    active?: boolean;
  };
}

export default gql`
  query ($findAllRealEstateInput: FindAllRealEstateInput!) {
    allRealEstate(findAllRealEstateInput: $findAllRealEstateInput) {
      id
      name
      active
      users {
        CNPJ
        email
      }
    }
  }
`;
