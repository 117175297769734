import { toast } from 'react-toastify';

const errorHandler = (err: any) => {
  err?.graphQLErrors.forEach((e: any) => {
    const message = e?.extensions?.response?.message;
    if (typeof message === 'string') return toast.error(message);
    message.map((m: string) => toast.error(m));
  });
};

export default errorHandler;
