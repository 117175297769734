import React from 'react';

import socket from '../services/websocket';

type Props = {
  children?: React.ReactNode;
};

interface ContextInterface {
  socket: typeof socket;
}

export const Context = React.createContext<ContextInterface>({
  socket,
});

const SocketContext: React.FC<Props> = ({ children }) => {
  return (
    <Context.Provider
      value={{
        socket,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SocketContext;
