import { gql } from '@apollo/client';
import { Status } from './entities/pac/status.enum';
import { Type } from './entities/pac/type.enum';

export interface Response {
  updatePac: {
    id: string;
  };
}

export interface Variables {
  updatePacInput?: {
    id: number;
    CPFClient?: string;
    nameClient?: string;
    CPFConjuge?: string;
    nameConjuge?: string;
    status?: Status;
    type?: Type;
    modality?: string;
    internalInfos?: string;
    product?: string;
    emailClient?: string;
    emailConj?: string;
    phoneClient?: string;
    phoneConj?: string;
    CEP?: string;
    address?: string;
    infos?: string;
  };
}

export default gql`
  mutation ($updatePacInput: UpdatePacInput!) {
    updatePac(updatePacInput: $updatePacInput) {
      id
    }
  }
`;
