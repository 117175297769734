export enum Role {
  USER = 'usuario',
  ADMIN = 'admin',
  CORRESPONDENTE = 'correspondente',
  COORDENADOR = 'coordernador(a)',
  GERENTE = 'gerente',
  PROMOTOR = 'promotor(a)',
  CORRETOR = 'corretor(a)',
  IMOBILIARIA = 'imobiliaria',
  CONSTRUTOR = 'construtor(a)',
  COLABORADOR = 'colaborador(a)',
  CREDENCIADO = 'credenciado(a)',
}

export enum RoleRealEstate {
  GERENTE = 'gerente',
  PROMOTOR = 'promotor(a)',
  CORRETOR = 'corretor(a)',
  COORDENADOR = 'coordernador(a)',
  CREDENCIADO = 'credenciado(a)',
}

export enum RoleConstructionCompany {
  COLABORADOR = 'colaborador(a)',
}
