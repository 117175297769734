import { gql } from '@apollo/client';
import { Notification } from './entities/notifications';

export interface Response {
  notifications: Notification[];
}

export default gql`
  query Notifications {
    notifications {
      id
      content
      toUrl
      title
      createdAt
      checked
    }
  }
`;
