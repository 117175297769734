import * as yup from 'yup';

import { Status } from '../../graphql/entities/pac/status.enum';
import { Type } from '../../graphql/entities/pac/type.enum';

export default yup.object({
  id: yup.number().required(),
  nameClient: yup
    .string()
    .trim()
    .optional()
    .min(3, 'O nome do cliente deve ter pelo menos 3 caracteres'),
  nameConjuge: yup.string().trim().optional(),
  CPFClient: yup.string().trim().optional().min(14, 'CPF inválido'),
  CPFConjuge: yup.string().trim().optional(),
  type: yup.mixed().oneOf(Object.values(Type)).optional(),
  status: yup.mixed().oneOf(Object.values(Status)).optional(),
  modality: yup.string().trim().optional(),
  product: yup.string().trim().optional(),
  internalInfos: yup.string().trim().optional(),
  content: yup
    .string()
    .trim()
    .optional()
    .min(3, 'A descrição da alteração deve ter pelo menos 3 caracteres'),
});
