import { useQuery } from "@apollo/client";
import {
  AccountCircle,
  Menu as MenuIcon,
  Notifications,
} from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogoImg from "../../assets/logo.png";
import { Notification as INotification } from "../../graphql/entities/notifications";
import { Context } from "../../store/SocketContext";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { reset } from "../../store/modules/auth/authSlice";
import NotificationsArea from "../Notifications";
import { Logo } from "./styles";

// Query
import notificationsQuery, { Response } from "../../graphql/notificationsQuery";

// Enums
import { Role } from "../../graphql/entities/role/enum";

// Utils
import verifyPermission from "../../utils/verifyPermission";

const pages = [
  ["Dashboard", "/"],
  ["Pacs", "/pacs"],
];

const settings = [["Sair", "logout"]];

const Header = () => {
  const roles = useAppSelector((state) => state.auth.user?.roles || []);
  const permission = useMemo(
    () => verifyPermission([Role.ADMIN], roles),
    [roles]
  );
  const { socket } = useContext(Context);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const [notificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const { data } = useQuery<Response>(notificationsQuery);

  const newNotificationSocket = useMemo(
    () => (newNotification: INotification) => {
      setNotifications([newNotification, ...notifications]);
    },
    [notifications]
  );

  useEffect(() => {
    socket.on("newNotification", (newNotification: INotification) => {
      newNotificationSocket(newNotification);
      toast.info("Você recebeu uma nova notificação.", {
        toastId: 100,
      });
    });
  }, [socket, newNotificationSocket]);

  useEffect(() => {
    setNotifications(data?.notifications || []);
  }, [data]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const actionNavMenu = (to: string) => {
    navigate(to);
  };

  const actionUserMenu = async (to: string) => {
    if (to === "logout") {
      socket.emit("logout", (await localStorage.getItem("userToken")) || "");
      dispatch(reset());
    }
    navigate(to);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <Logo src={LogoImg} alt="Unifica Cred" />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="Conta"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page[0]} onClick={() => actionNavMenu(page[1])}>
                  <Typography textAlign="center">{page[0]}</Typography>
                </MenuItem>
              ))}

              {verifyPermission([Role.IMOBILIARIA], roles) && (
                <MenuItem onClick={() => actionNavMenu("/imobiliaria")}>
                  <Typography textAlign="center">
                    Área da Imobiliária
                  </Typography>
                </MenuItem>
              )}
              {verifyPermission([Role.GERENTE], roles) && (
                <MenuItem
                  onClick={() => actionNavMenu("/imobiliaria/minhasequipes")}
                >
                  <Typography textAlign="center">Minhas Equipes</Typography>
                </MenuItem>
              )}
              {verifyPermission([Role.CONSTRUTOR], roles) && (
                <MenuItem onClick={() => actionNavMenu("/construtora")}>
                  <Typography textAlign="center">
                    Área da Construtora
                  </Typography>
                </MenuItem>
              )}
              {permission && (
                <MenuItem onClick={() => actionNavMenu("/admin")}>
                  <Typography textAlign="center">Admin</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Container
            sx={{
              display: { xs: "flex", md: "none" },
              mr: 1,
              justifyContent: "flex-start",
            }}
          >
            <Logo src={LogoImg} alt="Unifica Cred" />
          </Container>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page[0]}
                onClick={() => actionNavMenu(page[1])}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page[0]}
              </Button>
            ))}

            {verifyPermission([Role.CONSTRUTOR], roles) && (
              <Button
                onClick={() => actionNavMenu("/construtora")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Área da Construtora
              </Button>
            )}
            {verifyPermission([Role.IMOBILIARIA], roles) && (
              <Button
                onClick={() => actionNavMenu("/imobiliaria")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Área da Imobiliária
              </Button>
            )}
            {verifyPermission([Role.GERENTE], roles) && (
              <Button
                onClick={() => actionNavMenu("/imobiliaria/minhasequipes")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Minhas Equipes
              </Button>
            )}
            {permission && (
              <Button
                onClick={() => actionNavMenu("/admin")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Admin
              </Button>
            )}
          </Box>

          <Box marginX={4} sx={{ flexGrow: 0 }}>
            <Badge
              badgeContent={notifications.filter((n) => !n.checked).length}
              color="secondary"
              onClick={() => setIsNotificationsOpen(true)}
              sx={{ cursor: "pointer" }}
            >
              <Notifications color="inherit" />
            </Badge>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Abrir Configurações">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                color="inherit"
                size="large"
                aria-label="Configurações"
                aria-controls="menu-appbar"
                aria-haspopup="true"
              >
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  {user?.name}
                </Typography>
                <AccountCircle
                  fontSize="large"
                  sx={{ display: { xs: "none", sm: "block" } }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting[0]}
                  onClick={() => actionUserMenu(setting[1])}
                >
                  <Typography textAlign="center">{setting[0]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <NotificationsArea
        open={notificationsOpen}
        handleClose={setIsNotificationsOpen}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </AppBar>
  );
};
export default Header;
