import { gql } from '@apollo/client';

export interface Response {}

export interface Variables {
  recoveryPasswordInput: {
    email: string;
    password?: string;
    code?: string;
  };
}

export default gql`
  mutation ($recoveryPasswordInput: RecoveryPasswordInput!) {
    recoveryPassword(recoveryPasswordInput: $recoveryPasswordInput)
  }
`;
