import { gql } from '@apollo/client';
import { User } from './entities/user/index';

export interface Response {
  team: {
    id: string;
    name: string;
    owner: {
      id: string;
      name: string;
    };
    members: User[];
  };
}

export interface Variables {
  id: number;
}

export default gql`
  query team($id: Int!) {
    team(id: $id) {
      id
      name
      owner {
        id
        name
      }
      members {
        id
        name
        roles {
          name
        }
      }
    }
  }
`;
