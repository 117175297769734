import { gql } from "@apollo/client";

export interface Response {
  removeTeamUsers: Boolean;
}

export interface Variables {
  id: string;
  idTeam: number;
}

export default gql`
  mutation removeTeamUsers($id: String!, $idTeam: Int!) {
    removeTeamUsers(id: $id, idTeam: $idTeam)
  }
`;
