import { gql } from '@apollo/client';

export interface Response {
  removePac: { id: string };
}

export interface Variables {
  id: number;
}

export default gql`
  mutation ($id: Int!) {
    removePac(id: $id) {
      id
    }
  }
`;
