import { gql } from '@apollo/client';

export interface Response {
  createTeam: {
    id: string;
  };
}

export interface Variables {
  createTeamInput: {
    name: string;
    ownerId: string;
  };
}

export default gql`
  mutation ($createTeamInput: CreateTeamInput!) {
    createTeam(createTeamInput: $createTeamInput) {
      id
    }
  }
`;
