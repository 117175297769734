import { gql } from '@apollo/client';

export interface Response {}

export interface Variables {
  updateUserInput: {
    id: string;
    active: boolean;
    name: string;
    CPF: string;
    email: string;
  };
}

export default gql`
  mutation ($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
    }
  }
`;
