import { Box, Divider, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import 'moment/locale/pt-br';
import React from 'react';
import { History as IHistory } from '../../graphql/entities/history';

// import { Container } from './styles';

interface Props {
  data: IHistory;
}

const History: React.FC<Props> = ({ data }) => {
  return (
    <Box
      sx={{
        background: theme => theme.palette.grey[100],
        padding: 2,
        borderRadius: 2,
        margin: 1,
      }}
    >
      <time>
        {format(parseISO(String(data.createdAt) || ''), 'PPPPp', {
          locale: pt,
        })}
      </time>
      <Typography fontWeight="bold">{data.content}</Typography>
      <cite>Alteração feita por: {data?.user?.name}</cite>
      <Divider />
    </Box>
  );
};

export default History;
