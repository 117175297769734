import React from 'react';

import Header from '../../../components/Header';
import { Container } from './styles';

interface Props {
  children?: React.ReactNode;
  title?: string;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Header />
      {children}
    </Container>
  );
};

export default AuthLayout;
