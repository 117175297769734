import { gql } from '@apollo/client';

export interface Response {
  updateRealEstate: {
    id: string;
  };
}

export interface Variables {
  input: {
    id: string;
    name?: string;
    CNPJ?: string;
    email?: string;
    active?: boolean;
  };
}

export default gql`
  mutation ($input: UpdateRealEstateInput!) {
    updateRealEstate(updateRealEstateInput: $input) {
      id
    }
  }
`;
