import React, { useState } from 'react';

import axios from 'axios';
import apiBaseURL from '../../services/apiBaseURL';

// Import React FilePond
import { FilePondFile, ProcessServerConfigFunction } from 'filepond';
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
// @ts-ignore
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// @ts-ignore
import FilePondPluginSize from 'filepond-plugin-file-validate-size';
// @ts-ignore
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';
import 'filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css';

// Mutations

import { useAppSelector } from '../../store/hooks';
import { Container } from './styles';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginPdfPreview,
  FilePondPluginFileValidateType,
  FilePondPluginSize
);

interface Props {
  name: string;
  pacId: number;
}

const FileUpload: React.FC<Props> = ({ name, pacId }) => {
  const token = useAppSelector(state => state.auth.token) || '';
  const [files, setFiles] = useState<FilePondFile[]>([]);

  const upload: ProcessServerConfigFunction = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    // set data
    const formData = new FormData();
    formData.append('file', file);

    // related to aborting the request
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // the request itself
    axios({
      method: 'post',
      url: `${apiBaseURL}/documents/upload/${pacId}/${encodeURIComponent(
        name
      )}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: formData,
      cancelToken: source.token,
      onUploadProgress: e => {
        // updating progress indicator
        progress(e.lengthComputable, e.loaded, e.total);
      },
    })
      .then(response => {
        // passing the file id to FilePond
        load(response);
      })
      .catch(thrown => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
          error('Ocorreu um erro!');
        }
      });

    // Setup abort interface
    return {
      abort: () => {
        source.cancel('Operação cancelada pelo usuário.');
      },
    };
  };

  return (
    <Container>
      <FilePond
        acceptedFileTypes={['image/*', 'application/pdf']}
        files={files as any}
        onupdatefiles={setFiles}
        allowMultiple={true}
        instantUpload={false}
        server={{ process: upload }}
        name="files"
        maxFileSize={'15MB'}
        labelMaxFileSize={'O tamanho máximo de um arquivo é {filesize}'}
        labelMaxFileSizeExceeded={'O arquivo não pode ser maior que 15MB'}
        allowFileSizeValidation
        labelTapToCancel=""
        // @ts-ignore
        allowImageCrop
        labelIdle='Arraste os seus arquivos ou <span class="filepond--label-action">Clique</span>'
        labelFileProcessingError="Não foi possível fazer o upload!"
        labelFileProcessing="Fazendo Upload"
        allowRevert={false}
        labelFileProcessingComplete="Upload Completo! "
      />
    </Container>
  );
};

export default FileUpload;
