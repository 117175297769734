import { gql } from '@apollo/client';

export interface Response {
  createPacHistory: {
    content: string;
    createAt: Date;
    id: number;
    user: {
      name: string;
      id: string;
    };
    userId: string;
  };
}

export interface Variables {
  createHistoryInput: {
    pacId: number;
    content: string;
  };
}

export default gql`
  mutation ($createHistoryInput: CreateHistoryInput!) {
    createPacHistory(createHistoryInput: $createHistoryInput) {
      id
      content
      user {
        name
      }
      userId
      createdAt
    }
  }
`;
