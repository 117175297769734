import React, { useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { RoleConstructionCompany } from '../../graphql/entities/role/enum';
import { User } from '../../graphql/entities/user';
import { useAppSelector } from '../../store/hooks';

// Utils
import errorHandler from '../../utils/errorHandler';
import verifyCPF from '../../utils/verifyCPF';

// Components
import CPFInput from '../../components/CPFInput';

// Queries
import getUsersConstructionCompany, {
  Response,
  Variables,
} from '../../graphql/getUsersConstructionCompany';

// Mutations
import addUserConstructionCompany, {
  Response as RAddUser,
  Variables as VAddUser,
} from '../../graphql/addUserConstructionCompany';

import updateUserConstructionCompany, {
  Response as RUpdateEstate,
  Variables as VUpdateEstate,
} from '../../graphql/updateUserConstructionCompany';

const transformData = (users: User[] | undefined) => {
  if (users === undefined) return [];
  return users.map(u => {
    const customRoles = u.roles as any;
    return {
      ...u,
      documentId: u.CPF || u.CNPJ,
      active: String(u.active) === 'true' ? 'Sim' : 'Não',
      roles: customRoles
        .map((u: string) => u.charAt(0).toLocaleUpperCase() + u.slice(1))
        .join(', '),
    };
  });
};

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Nome', width: 400 },
  { field: 'documentId', headerName: 'CPF/CNPJ', width: 150 },
  { field: 'email', headerName: 'Email', width: 400 },
  { field: 'roles', headerName: 'Cargo', width: 300 },
  { field: 'active', headerName: 'Ativo', width: 70, flex: 1 },
];

const Loading: React.FC = () => (
  <Box margin={5}>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={118}
      sx={{ margin: 1 }}
    />
    <Skeleton sx={{ margin: 1 }} variant="rectangular" animation="wave" />
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={118}
      sx={{ margin: 1 }}
    />
  </Box>
);

const ConstructionCompany: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dialogEditUserIsOpen, setDialogEditUserIsOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | null | undefined>(
    null
  );
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const {
    register: registerEditUserDialog,
    handleSubmit: handleEditUserSubmitDialog,
    setValue,
    getValues,
  } = useForm();
  const { register: registerAddUser, handleSubmit: handleSubmitAddUser } =
    useForm();

  const constructionCompany = useAppSelector(
    state => state.auth.user?.constructionCompany
  );
  const {
    data,
    loading: loadingQuery,
    refetch,
  } = useQuery<Response, Variables>(getUsersConstructionCompany, {
    variables: { id: Number(constructionCompany?.id) || 0 },
  });

  const [runAddUser] = useMutation<RAddUser, VAddUser>(
    addUserConstructionCompany
  );
  const [runUpdateUserConstructionCompany] = useMutation<
    RUpdateEstate,
    VUpdateEstate
  >(updateUserConstructionCompany);

  const onSubmitAddUser = async (data: any) => {
    let typedData: VAddUser['input'] = data as VAddUser['input'];

    if (typedData.name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    if (!verifyCPF(typedData.CPF)) return toast.warn('CPF Inválido');

    if (data.roles.length === 0) return toast.warn('Selecione uma permissão');

    setLoading(true);
    try {
      await runAddUser({
        variables: {
          input: typedData,
        },
      });

      toast.success('Usuário criado com sucesso!');
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  };

  const onSubmitEditUser = async (data: any) => {
    if (typeof data.roles === 'string') data.roles = data.roles.split(',');
    let typedData: VUpdateEstate['input'] = data as VUpdateEstate['input'];

    if (typedData?.name && typedData.name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    if (typedData?.CPF && !verifyCPF(typedData.CPF))
      return toast.warn('CPF Inválido');

    if (typedData?.roles && typedData?.roles.length === 0)
      return toast.warn('Selecione uma permissão');

    setLoading(true);
    try {
      await runUpdateUserConstructionCompany({
        variables: {
          input: typedData,
        },
      });

      toast.success('Usuário atualizado com sucesso!');
      refetch();
    } catch (err: any) {
      errorHandler(err);
    }
    setDialogEditUserIsOpen(false);
    setLoading(false);
  };

  if (loadingQuery) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Typography
          marginBottom={2}
          variant="h5"
          color={theme => theme.palette.secondary.main}
        >
          <strong>Construtor(a): </strong>
          {constructionCompany?.name}
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" color={theme => theme.palette.primary.main}>
          <strong>Membros</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          hideFooterPagination
          rows={transformData(data?.constructionCompany.users) || []}
          columns={columns}
          isRowSelectable={() => false}
          onRowClick={params => {
            if (params.row.roles.includes('Imobiliaria'))
              return toast.warn(
                'Você não pode editar o usuário da imobiliária'
              );
            setUserSelected(params.row);
            setValue('id', params.row.id);
            setValue('name', params.row.name || '');
            setValue('email', params.row.email || '');
            setValue('CPF', params.row.CPF || '');
            setValue('active', params.row.active === 'Sim');
            setValue(
              'roles',
              data?.constructionCompany.users.find(u => u.id === params.row?.id)
                ?.roles || []
            );
            setDialogEditUserIsOpen(true);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmitAddUser(onSubmitAddUser)}>
          <Accordion sx={{ margin: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Cadastrar Usuário</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                sx={{ margin: 1 }}
                fullWidth
                label="Nome"
                {...registerAddUser('name')}
                required
              />
              <TextField
                sx={{ margin: 1 }}
                fullWidth
                type="email"
                label="Email"
                {...registerAddUser('email')}
                required
              />
              <CPFInput required register={registerAddUser} name="CPF" />
              <FormControl fullWidth sx={{ margin: 1 }}>
                <InputLabel id="select-roles-label">Permissões</InputLabel>
                <Select
                  multiple
                  labelId="select-roles-label"
                  label="Permissões"
                  required
                  defaultValue={[]}
                  {...registerAddUser('roles')}
                >
                  {Object.values(RoleConstructionCompany).map(r => (
                    <MenuItem value={r} key={r}>
                      {r}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}
              >
                <Button type="submit">Cadastrar</Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </form>
      </Grid>
      <Backdrop
        sx={{
          color: theme => theme.palette.primary.main,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <form onSubmit={handleEditUserSubmitDialog(onSubmitEditUser)}>
        <Dialog
          open={dialogEditUserIsOpen}
          onClose={() => setDialogEditUserIsOpen(false)}
          maxWidth={'xs'}
          fullWidth
        >
          <DialogTitle>Editar Usuário</DialogTitle>
          <DialogContent dividers>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={getValues('active')}
                  onChange={e => {
                    setValue('active', e.target.checked);
                  }}
                />
              }
              label="Ativo"
              sx={{ margin: 1 }}
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              label="Nome"
              {...registerEditUserDialog('name')}
              required
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerEditUserDialog('email')}
              required
            />
            <CPFInput required register={registerEditUserDialog} name="CPF" />
            <FormControl fullWidth sx={{ margin: 1 }}>
              <InputLabel id="select-roles-label">Permissões</InputLabel>
              <Select
                multiple
                labelId="select-roles-label"
                label="Permissões"
                required
                defaultValue={
                  data?.constructionCompany.users.find(
                    u => u.id === userSelected?.id
                  )?.roles || []
                }
                {...registerEditUserDialog('roles')}
              >
                {Object.values(RoleConstructionCompany).map(r => (
                  <MenuItem value={r} key={r}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogEditUserIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                buttonSubmitRef.current && buttonSubmitRef?.current?.click()
              }
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <button type="submit" hidden ref={buttonSubmitRef} />
      </form>
    </Grid>
  );
};

export default ConstructionCompany;
