import { gql } from '@apollo/client';
import { Pac } from './entities/pac/index';

export interface Response {
  pac: Omit<Pac, 'internalInfos'> & { internalInfos?: string } & Omit<
      Pac,
      'infos'
    > & { infos?: string };
}

export interface Variables {
  id: number;
}

export default gql`
  query pac($id: Int!) {
    pac(id: $id) {
      id
      infos
      emailClient
      emailConj
      phoneClient
      phoneConj
      CEP
      address
      nameClient
      CPFClient
      nameConjuge
      CPFConjuge
      type
      modality
      product
      status
      internalInfos
      createdAt
      updatedAt
      constructionCompany {
        name
      }
      operator {
        name
      }
      user {
        id
        name
        realEstate {
          name
        }
        TeamUsers{
            team{
              name
              owner{
                name
              }
            }
          }
        constructionCompany {
          name
        }
      }
      histories {
        id
        content
        createdAt
        user {
          name
        }
      }
      documents {
        id
        key
        name
        protected
        url
        approved
        createdAt
      }
    }
  }
`;
