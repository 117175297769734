import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Routes from './routes';
import api from './services/api';
import { useAppSelector } from './store/hooks';
import ContextProvider from './store/SocketContext';
import theme from './styles/theme';

function App() {
  const token = useAppSelector(state => state.auth.token) || '';

  return (
    <ApolloProvider client={api(token)}>
      <ContextProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Routes />
            <CssBaseline />
            <ToastContainer
              autoClose={3000}
              position="bottom-right"
              newestOnTop
            />
          </ThemeProvider>
        </BrowserRouter>
      </ContextProvider>
    </ApolloProvider>
  );
}

export default App;
