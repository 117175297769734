import { useLazyQuery } from "@apollo/client";
import {
  Clear as ClearIcon,
  ArrowLeft as LeftIcon,
  ArrowRight as RightIcon,
  Search as SearchIcon,
  Today as TodayIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Skeleton,
  useTheme,
} from "@mui/material";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppSelector } from "../../store/hooks";

// Enum
import { Status } from "../../graphql/entities/pac/status.enum";
import { Role } from "../../graphql/entities/role/enum";

// Query
import dashboardQuery, {
  Response,
  Variables,
} from "../../graphql/dashboardQuery";

// Utils
import verifyPermission from "../../utils/verifyPermission";

// Component
import CardStatus from "../../components/CardStatus";
import DialogSelectUser from "../../components/DialogSelectUser";

// API
import axios from "axios";
import apiBaseURL from "../../services/apiBaseURL";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  const roles = useAppSelector((state) => state.auth.user?.roles);
  const realEstate = useAppSelector(
    (state) => state.auth.user?.realEstate?.id || undefined
  );
  const token = useAppSelector((state) => state.auth.token) || "";

  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [userSelected, setUserSelected] = useState(["", ""]);
  const userInputRef = useRef<HTMLInputElement>(null);

  const [date, setDate] = useState(moment().startOf("month"));
  const [runDashboardQuery] = useLazyQuery<Response, Variables>(dashboardQuery);

  const status = useMemo(() => Object.values(Status).sort(), []);
  const [data, setData] = useState<{ [key: string]: number }>(
    Object.values(Status).reduce(
      (previous, current) => ({ ...previous, [`${current}`]: 0 }),
      {} as any
    )
  );

  const permission = useMemo(
    () =>
      verifyPermission(
        [
          Role.ADMIN,
          Role.COORDENADOR,
          Role.CORRESPONDENTE,
          Role.GERENTE,
          Role.IMOBILIARIA,
          Role.CONSTRUTOR,
          Role.COLABORADOR,
        ],
        roles || []
      ),
    [roles]
  );

  const cards = useMemo(
    () => [
      { title: Status.CONSULTACPF, background: "#Ec9323" },
      { title: Status.CPFREST, background: theme.palette.error.main },
      { title: Status.CPFSEMREST, background: theme.palette.success.light },
      { title: Status.DOCENV, background: theme.palette.info.main },
      { title: Status.DOCANS, background: theme.palette.info.main },
      { title: Status.DOCPEN, background: "#Ec9323" },
      { title: Status.ANSPROP, background: "#Ec9323" },
      { title: Status.PROPREP, background: theme.palette.error.main },
      { title: Status.PROPAPR, background: theme.palette.success.main },
      { title: Status.CONTBOLGER, background: theme.palette.success.main },
      { title: Status.BANCANS, background: "#Ec9323" },
      { title: Status.BANCANSREP, background: theme.palette.error.main },
      { title: Status.RENDREP, background: theme.palette.error.main },
      { title: Status.RENDAP, background: theme.palette.success.main },
      { title: Status.APROVSICAQ, background: theme.palette.success.main },
      { title: Status.APROVCOND, background: theme.palette.success.light },
      { title: Status.AGNDADO, background: theme.palette.info.main },
      { title: Status.ASSINADO, background: theme.palette.success.main },
      { title: Status.AGDCONF, background: "#Ec9323" },
      { title: Status.INCONFORME, background: theme.palette.error.main },
      { title: Status.CONFORME, background: theme.palette.success.main },
      { title: Status.MNTGDOSSIE, background: "#Ec9323" },
      { title: Status.ENTDOSSIE, background: "#Ec9323" },
      { title: Status.AGDOSSAGENCIA, background: theme.palette.info.main },
      { title: Status.ASSAGENCIA, background: theme.palette.success.main },
      { title: Status.ASSGER, background: "#Ec9323" },
      { title: Status.FIND, background: theme.palette.error.main },
      { title: Status.FINC, background: theme.palette.success.main },
    ],
    [theme]
  );

  const newCards = useMemo(
    () => [
      { title: Status.ITBIRGI, background: "#Ec9323" },
      { title: Status.INT, background: "#Ec9323" },
      { title: Status.DISC, background: "#Ec9323" },
      { title: Status.CADSIOPI, background: "#Ec9323" },
      { title: Status.AGUARDCONS, background: "#Ec9323" },
      { title: Status.ABENG, background: "#Ec9323" },
      { title: Status.AGUARDVIS, background: "#Ec9323" },
      { title: Status.VISTOK, background: theme.palette.success.main },
      { title: Status.LAUDOPEN, background: "#Ec9323" },
      { title: Status.LAUDOOK, background: theme.palette.success.main },
      { title: Status.RETCONTRGI, background: theme.palette.info.main },
      { title: Status.ENTCONTRGI, background: theme.palette.success.main },
      { title: Status.DOCEMP, background: "#Ec9323" },
      { title: Status.SOLDP, background: theme.palette.info.light },
      { title: Status.AGNDENT, background: theme.palette.info.light },
      { title: Status.AGUAAP, background: "#Ec9323" },
      { title: Status.CADFAZ, background: theme.palette.info.light },
      { title: Status.ANSFAZ, background: theme.palette.info.light },
      { title: Status.FAZOK, background: theme.palette.success.main },
    ],
    [theme]
  );

  async function generateReport() {
    let url = apiBaseURL;
    try {
      toast.warn("Aguarde, gerando o arquivo...");

      const { data } = await axios.get(`${url}/report`, {
        params: {
          begin: date.startOf("month").toISOString(),
          end: date.endOf("month").toISOString(),
        },
        responseType: "blob",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      const href = URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "Relatorio.xlsx");
      link.click();
    } catch (err) {
      console.log(err);
      toast.error("Não foi possível gerar o relatório");
    }
  }

  useEffect(() => {
    async function getInfos() {
      try {
        const { data: dataQuery } = await runDashboardQuery({
          variables: {
            updatedAtBegin: date.startOf("month").toISOString(),
            updatedAtEnd: date.endOf("month").toISOString(),
            userId: userSelected[0].length <= 0 ? undefined : userSelected[0],
          },
        });

        return Object.entries(Status).reduce(
          (previous, current) => ({
            ...previous,
            [`${current[1]}`]: dataQuery[current[0]].totalPacs,
          }),
          {} as any
        );
      } catch (err: any) {
        toast.error(err?.message);
        return 0;
      }
    }

    async function run() {
      setLoading(true);
      setData(await getInfos());
      setLoading(false);
    }

    run();
  }, [date, runDashboardQuery, status, userSelected]);

  useEffect(() => {
    if (userInputRef.current) userInputRef.current.value = userSelected[1];
  }, [userSelected]);

  if (loading) {
    return (
      <Box margin={5}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={118}
          sx={{ margin: 1 }}
        />
        <Skeleton sx={{ margin: 1 }} variant="rectangular" animation="wave" />
        <Box sx={{ pt: 0.5, margin: 1 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ pt: 0.5, margin: 1 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ pt: 0.5, margin: 1 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ pt: 0.5, margin: 1 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={118}
          sx={{ margin: 1 }}
        />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} padding={2}>
      {permission && (
        <Grid item xs={12} sx={{ textAlign: "center" }} marginBottom={2}>
          <Button onClick={generateReport} variant="contained">
            Gerar Relatório Mensal
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }} marginBottom={2}>
        {!verifyPermission([Role.CONSTRUTOR, Role.COLABORADOR], roles || []) &&
          permission && (
            <FormControl variant="outlined" sx={{ margin: 1 }}>
              <InputLabel htmlFor="user">Responsável</InputLabel>
              <OutlinedInput
                inputRef={userInputRef}
                disabled={userSelected[0].length > 0}
                label="Responsável"
                inputProps={{
                  defaultValue: userSelected[1],
                }}
                name="user"
                sx={{ paddingRight: 3 }}
                endAdornment={
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Buscar responsável"
                        edge="end"
                        onClick={() => {
                          if (
                            userInputRef &&
                            userInputRef?.current &&
                            userInputRef.current?.value.length < 3
                          )
                            return toast.warn(
                              "Digite pelo menos 3 caracteres."
                            );

                          setUserDialogOpen(true);
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Limpar"
                        edge="end"
                        onClick={() => setUserSelected(["", ""])}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                }
              />
            </FormControl>
          )}

        {/* SELECT DAY */}
        <Grid item xs={12} sx={{ textAlign: "center" }} marginBottom={2}>
          <IconButton onClick={() => setDate(moment().startOf("month"))}>
            <TodayIcon fontSize="large" color="primary" />
          </IconButton>
          <IconButton
            onClick={() => setDate(moment(date.subtract(1, "month")))}
          >
            <LeftIcon fontSize="large" color="secondary" />
          </IconButton>

          <time
            style={{
              margin: "0 15px",
              fontSize: "15px",
            }}
          >
            <strong>{date.format("MMMM/yyyy").toUpperCase()}</strong>
          </time>
          <IconButton onClick={() => setDate(moment(date.add(1, "month")))}>
            <RightIcon fontSize="large" color="secondary" />
          </IconButton>
        </Grid>
        {/* SELECT DAY */}

        <Grid
          container
          padding={1}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              background: "rgba(85, 23, 143, 0.7)",
              borderRadius: 16,
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              border: "1px solid rgba(85, 23, 143, 0.3)",
            }}
            padding={1}
          >
            <Chart
              type="donut"
              series={status.map((s) => data[s])}
              options={{
                title: {
                  text: "Resumo do mês",
                  align: "center",
                  style: {
                    color: "white",
                  },
                },
                chart: { type: "donut" },
                labels: status,
                legend: {
                  show: false,
                },
                plotOptions: {
                  pie: {
                    expandOnClick: true,
                    donut: {
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          showAlways: true,
                          color: "white",
                        },
                        value: {
                          color: "white",
                        },
                      },
                    },
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          padding={1}
          marginTop={10}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cards.map((c) => (
            <CardStatus
              key={c.title}
              title={c.title}
              quantity={data[c.title]}
              onClick={() =>
                navigate(
                  `/pacs?${createSearchParams(
                    new URLSearchParams({
                      status: c.title,
                      updatedAt: date.format("YYYY-MM-DD"),
                    })
                  ).toString()}`,
                  {
                    state: {
                      user:
                        userSelected[0].length > 0
                          ? { name: userSelected[1], id: userSelected[0] }
                          : undefined,
                    },
                  }
                )
              }
              backgroundColor={c.background}
            />
          ))}
          <Box width={"100%"} margin={1} marginBottom={10}>
            <Divider variant="fullWidth" />
            <Divider variant="fullWidth" />
            <Divider variant="fullWidth" />
          </Box>

          {newCards.map((c) => (
            <CardStatus
              key={c.title}
              title={c.title}
              quantity={data[c.title]}
              onClick={() =>
                navigate(
                  `/pacs?${createSearchParams(
                    new URLSearchParams({
                      status: c.title,
                      updatedAt: date.format("YYYY-MM-DD"),
                    })
                  ).toString()}`,
                  {
                    state: {
                      user:
                        userSelected[0].length > 0
                          ? { name: userSelected[1], id: userSelected[0] }
                          : undefined,
                    },
                  }
                )
              }
              backgroundColor={c.background}
            />
          ))}
          <Grid item xs={12}>
            <cite>Versão 1.6</cite>
          </Grid>
        </Grid>
        {permission && (
          <DialogSelectUser
            name={userInputRef.current?.value || ""}
            open={userDialogOpen}
            setIsOpen={setUserDialogOpen}
            setUser={setUserSelected}
            realEstateId={
              Number(realEstate) !== 0 ? Number(realEstate) : undefined
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
