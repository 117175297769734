import { gql } from '@apollo/client';

export interface Response {
  bindPacOperator: {
    id: number;
  };
}

export interface Variables {
  input: {
    id: number;
  };
}

export default gql`
  mutation ($input: BindPacOperator!) {
    bindPacOperator(input: $input) {
      id
    }
  }
`;
