import { gql } from "@apollo/client";
import { User } from "./entities/user/index";

export interface Response {
  myteams: {
    id: string;
    name: string;
    owner: {
      id: string;
      name: string;
    };
    members: User[];
  }[];
}

export interface Variables {}

export default gql`
  query {
    myteams {
      id
      name
      owner {
        id
        name
      }
      members {
        id
        name
        roles {
          name
        }
      }
    }
  }
`;
