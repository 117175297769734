import { TextField } from '@mui/material';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import InputMask from 'react-input-mask';

// import { Container } from './styles';

interface Props {
  register: UseFormRegister<any>;
  name: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
}

const CNPJInput: React.FC<Props> = ({
  register,
  name,
  disabled = false,
  label,
  required,
}) => {
  return (
    //@ts-ignore
    <InputMask
      mask="99.999.999/9999-99"
      disabled={disabled}
      maskPlaceholder=" "
      {
        //@ts-ignore
        ...register(name)
      }
      required={required}
    >
      {
        //@ts-ignore
        () => (
          <TextField
            required={required}
            label={label || 'CNPJ'}
            fullWidth
            sx={{ margin: 1 }}
            variant="outlined"
            {
              //@ts-ignore
              ...register(name)
            }
            disabled={disabled}
          />
        )
      }
    </InputMask>
  );
};

export default CNPJInput;
