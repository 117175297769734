import * as firebase from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

export const inicializarFirebase = () => {
  const app = firebase.initializeApp({
    apiKey: 'AIzaSyAaDJSAwTaIiefCs6wFbXeGrIPwTgBwZlE',
    authDomain: 'unificacred.firebaseapp.com',
    projectId: 'unificacred',
    storageBucket: 'unificacred.appspot.com',
    messagingSenderId: '210615657490',
    appId: '1:210615657490:web:17a7354a4db5a5d58424cb',
    measurementId: 'G-DFERRKKHJJ',
  });

  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(async registration => {
      const messaging = getMessaging(app);
      getPermission();
      const userToken = await getToken(messaging, {
        serviceWorkerRegistration: registration,
      });

      localStorage.setItem('userToken', userToken);
    });
};

function getPermission() {
  return Notification.requestPermission();
}
