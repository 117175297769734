import { gql } from '@apollo/client';
import { User } from './entities/user';

export interface Response {
  getUsers: {
    users: User[];
    page: number;
    totalPages: number;
    usersInPage: number;
    totalUsers: number;
  };
}

export default gql`
  query usersFindToPac($page: Int!, $name: String!, $realEstateId: Int) {
    getUsers(
      getUsersInput: { page: $page, name: $name, realEstateId: $realEstateId }
    ) {
      users {
        id
        name
      }
      page
      totalPages
      usersInPage
      totalUsers
    }
  }
`;
