import { useMutation } from '@apollo/client';
import { CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../store/hooks';

// Assets
import logo from '../../assets/logo2.png';

// Mutation
import recoveryPassword, {
  Response,
  Variables,
} from '../../graphql/recoveryPassword';

// Utils
import errorHandler from '../../utils/errorHandler';

// import { Container } from './styles';

const steps = ['Enviar o código', 'Criar nova senha', 'Confirmação'];

const RecoveryPassword: React.FC = () => {
  const navigate = useNavigate();
  const logged = useAppSelector(state => state.auth.logged);

  const theme = useTheme();

  const [runRecoveryPassword] = useMutation<Response, Variables>(
    recoveryPassword
  );

  const { register, handleSubmit } = useForm();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (logged) navigate('/');
  }, [logged, navigate]);

  async function onSubmit(data: any) {
    let variables: Variables = {
      recoveryPasswordInput: {
        email: data.email,
      },
    };

    if (activeStep === 1) {
      if (data.password.length < 8)
        return toast.warn('A senha deve conter pelo menos 8 caracteres');
      if (data.code.length < 1) return toast.warn('Insira um código válido');

      variables = {
        recoveryPasswordInput: {
          ...variables.recoveryPasswordInput,
          code: data.code,
          password: data.password,
        },
      };
    }

    setLoading(true);
    try {
      await runRecoveryPassword({
        variables,
      });

      if (activeStep === 0) {
        setActiveStep(1);
      }

      if (activeStep === 1) {
        setActiveStep(2);
      }
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  }

  return (
    <Grid
      container
      sx={{
        background: theme => theme.palette.primary.main,
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        style={{ width: '125px', margin: '15px', marginBottom: '30px' }}
        src={logo}
        alt="Unifica Cred"
      />
      <Container
        maxWidth="xs"
        sx={{
          borderRadius: 5,
          padding: 2,
          background: theme => theme.palette.common.white,
          color: theme => theme.palette.primary.main,
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ display: 'flex' }} padding={2}>
          <form
            style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {activeStep === 0 && (
              <>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  type="email"
                  {...register('email')}
                />
                <Button sx={{ alignSelf: 'center', marginY: 1 }} type="submit">
                  Enviar
                </Button>
              </>
            )}
            {activeStep === 1 && (
              <>
                <TextField
                  label="Código"
                  fullWidth
                  required
                  type="text"
                  {...register('code')}
                />
                <FormControl margin="normal">
                  <InputLabel htmlFor="password">Nova Senha</InputLabel>
                  <OutlinedInput
                    autoComplete="current-password"
                    required
                    {...register('password')}
                    type={showPass ? 'text' : 'password'}
                    label="Nova Senha"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPass(!showPass)}>
                          {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Button sx={{ alignSelf: 'center', marginY: 1 }} type="submit">
                  Enviar
                </Button>
              </>
            )}
            {activeStep === 2 && (
              <>
                <CheckCircle
                  sx={{ fontSize: '50px', alignSelf: 'center', marginY: 2 }}
                  color="success"
                />
                <Typography variant="h5" textAlign="center">
                  Alteração feita com sucesso!
                </Typography>
              </>
            )}
            <Link
              to="/login"
              style={{
                textAlign: 'center',
                textDecoration: 'none',
                color: theme.palette.grey.A700,
                opacity: 1,
                margin: '5px',
              }}
            >
              Voltar ao Início
            </Link>
          </form>
        </Box>
      </Container>
      <Backdrop
        sx={{
          color: theme => theme.palette.primary.main,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </Grid>
  );
};

export default RecoveryPassword;
