import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Clear as ClearIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

// Types
import { Role } from '../../graphql/entities/role/enum';
import { User } from '../../graphql/entities/user';

// Query and Mutations
import getAllConstructionCompany, {
  Response as RAllConstructionCompany,
  Variables as VAllConstructionCompany,
} from '../../graphql/allConstructionCompany';
import getAllRealEstate, {
  Response as RAllRealEstate,
  Variables as VAllRealEstate,
} from '../../graphql/allRealEstate';
import createConstructionCompany, {
  Response as RCreateConstructionCompany,
  Variables as VCreateConstructionCompany,
} from '../../graphql/createConstructionCompany';
import createRealEstate, {
  Response as RCreateRealEstate,
  Variables as VCreateRealEstate,
} from '../../graphql/createRealEstate';
import createUser, {
  Response as RCreateUser,
  Variables as VCreateUser,
} from '../../graphql/createUser';
import deleteUser, {
  Response as RDeleteUser,
  Variables as VDeleteUser,
} from '../../graphql/deleteUser';
import getUsers, { Response, Variables } from '../../graphql/getUsers';
import updateConstructionCompany, {
  Response as RUpdateConstructionCompany,
  Variables as VUpdateConstructionCompany,
} from '../../graphql/updateConstructionCompany';
import updateRealEstate, {
  Response as RUpdateRealEstate,
  Variables as VUpdateRealEstate,
} from '../../graphql/updateRealEstate';
import updateUser, {
  Response as RUpdateUser,
  Variables as VUpdateUser,
} from '../../graphql/updateUser';

// Components
import CNPJInput from '../../components/CNPJInput';
import CPFInput from '../../components/CPFInput';

// Utils
import errorHandler from '../../utils/errorHandler';
import verifyCPF from '../../utils/verifyCPF';

interface UserFormatted extends Omit<User, 'roles'> {
  roles: string;
}

// import { Container } from './styles';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Nome', width: 400 },
  { field: 'CPF', headerName: 'CPF', width: 130 },
  { field: 'email', headerName: 'Email', width: 300 },
  {
    field: 'realEstate',
    headerName: 'Imobiliária',
    width: 300,
    valueGetter: params => {
      return params.row.realEstate?.name || '';
    },
  },
  { field: 'roles', headerName: 'Permissões', width: 300 },
  { field: 'active', headerName: 'Ativo', flex: 1 },
];

const columnsRealEstate: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 20 },
  { field: 'name', headerName: 'Imobiliária', width: 400 },
  {
    field: 'CNPJ',
    headerName: 'CNPJ',
    width: 200,
    valueGetter: params => params.row.users[0].CNPJ || '',
  },
  {
    field: 'email',
    headerName: 'email',
    width: 400,
    valueGetter: params => params.row.users[0].email || '',
  },
  { field: 'active', headerName: 'Ativo', flex: 1 },
];

const columnsConstructionCompany: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 20 },
  { field: 'name', headerName: 'Construtor(a)', width: 400 },
  {
    field: 'CNPJ',
    headerName: 'CNPJ',
    width: 200,
    valueGetter: params => params.row.users[0].CNPJ || '',
  },
  {
    field: 'email',
    headerName: 'email',
    width: 400,
    valueGetter: params => params.row.users[0].email || '',
  },
  { field: 'active', headerName: 'Ativo', flex: 1 },
];

const Loading: React.FC = () => (
  <Box margin={5}>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={118}
      sx={{ margin: 1 }}
    />
    <Skeleton sx={{ margin: 1 }} variant="rectangular" animation="wave" />
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={118}
      sx={{ margin: 1 }}
    />
  </Box>
);

const Users: React.FC = () => {
  // Form config
  const { register, handleSubmit } = useForm();
  const {
    register: registerDialog,
    handleSubmit: handleSubmitDialog,
    setValue,
  } = useForm();
  const { register: registerNewUserForm, handleSubmit: handleSubmitNewUser } =
    useForm();
  const {
    register: registerNewRealEstate,
    handleSubmit: handleSubmitRealEstate,
  } = useForm();
  const {
    register: registerEditRealEstate,
    handleSubmit: handleSubmitEditRealEstate,
    getValues: getEditRealEstateValues,
    setValue: setEditRealEstateValue,
  } = useForm();

  const {
    register: registerNewConstructionCompany,
    handleSubmit: handleSubmitConstructionCompany,
  } = useForm();
  const {
    register: registerEditConstructionCompany,
    handleSubmit: handleSubmitEditConstructionCompany,
    getValues: getEditConstructionCompanyValues,
    setValue: setEditConstructionCompanyValue,
  } = useForm();

  const [loadUsers] = useLazyQuery<Response, Variables>(getUsers);
  const {
    data: dataAllRealEstate,
    refetch: refreshRealEstate,
    loading: loadingAllRealEstate,
  } = useQuery<RAllRealEstate, VAllRealEstate>(getAllRealEstate, {
    variables: { findAllRealEstateInput: {} },
  });
  const {
    data: dataAllConstructionCompany,
    refetch: refreshAllConstructionCompany,
    loading: loadingAllConstructionCompany,
  } = useQuery<RAllConstructionCompany, VAllConstructionCompany>(
    getAllConstructionCompany,
    {
      variables: { findAllConstructionCompanyInput: {} },
    }
  );
  const [runDeleteUser] = useMutation<RDeleteUser, VDeleteUser>(deleteUser);
  const [runUpdateUser] = useMutation<RUpdateUser, VUpdateUser>(updateUser);
  const [runCreateUser] = useMutation<RCreateUser, VCreateUser>(createUser);
  const [runCreateRealEstate] = useMutation<
    RCreateRealEstate,
    VCreateRealEstate
  >(createRealEstate);
  const [runUpdateRealEstate] = useMutation<
    RUpdateRealEstate,
    VUpdateRealEstate
  >(updateRealEstate);

  const [runCreateConstructionCompany] = useMutation<
    RCreateConstructionCompany,
    VCreateConstructionCompany
  >(createConstructionCompany);
  const [runUpdateConstructionCompany] = useMutation<
    RUpdateConstructionCompany,
    VUpdateConstructionCompany
  >(updateConstructionCompany);

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState<undefined | string>(undefined);

  const [page, setPage] = useState(1);
  const [users, setUsers] = useState<UserFormatted[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);

  const [dialogEditUserIsOpen, setDialogEditUserIsOpen] = useState(false);
  const [dialogEditRealEstateIsOpen, setDialogEditRealEstateIsOpen] =
    useState(false);
  const [
    dialogEditConstructionCompanyIsOpen,
    setDialogEditConstructionCompanyIsOpen,
  ] = useState(false);

  const [userSelected, setUserSelected] = useState<User | null | undefined>(
    null
  );

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const buttonEditRealEstateSubmitRef = useRef<HTMLButtonElement>(null);
  const buttonEditConstructionCompanySubmitRef =
    useRef<HTMLButtonElement>(null);

  useEffect(() => {
    async function run() {
      setLoading(true);
      try {
        const { data } = await loadUsers({
          variables: {
            getUsersInput: {
              page: Number(page),
              name,
            },
          },
        });

        setUsers(
          data?.getUsers.users.map(u => ({
            ...u,
            roles:
              u.roles?.reduce(
                (p, n, i) =>
                  i === 0
                    ? `${n.name.toUpperCase()}`
                    : `${p}, ${n.name.toUpperCase()}`,
                ''
              ) || '',
          })) || []
        );
        setTotalUsers(data?.getUsers.totalUsers || 0);
      } catch (err: any) {
        toast.error(err?.message);
      }
      setLoading(false);
    }

    run();
  }, [loadUsers, page, name]);

  useEffect(() => {
    setValue('name', userSelected?.name);
    setValue('CPF', userSelected?.CPF);
    setValue('active', userSelected?.active);
    setValue('email', userSelected?.email);
  }, [userSelected, setValue]);

  function onSubmit(data: any) {
    const name = data?.name || '';

    if (name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    setName(name);
  }

  async function onSubmitDialog(data: any) {
    const name = data?.name || '';

    if (name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    if (!verifyCPF(data.CPF)) return toast.warn('CPF Inválido');

    try {
      await runUpdateUser({
        variables: {
          updateUserInput: {
            id: userSelected?.id,
            ...data,
          },
        },
      });

      setUsers(
        users.map(u => {
          if (u.id === userSelected?.id) {
            delete data['currentPassword'];
            return { ...u, ...data };
          }
          return u;
        })
      );

      setDialogEditUserIsOpen(false);
    } catch (err: any) {
      errorHandler(err);
    }
  }

  async function onSubmitNewUser(data: any) {
    const name = data?.name || '';

    if (name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    if (!verifyCPF(data.CPF)) return toast.warn('CPF Inválido');

    if (data.roles.length === 0) return toast.warn('Selecione uma permissão');

    setLoading(true);
    try {
      await runCreateUser({
        variables: {
          createUserInput: {
            ...data,
          },
        },
      });

      toast.success('Usuário criado com sucesso!');
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  }

  async function onSubmitRealEstate(data: any) {
    const name = data?.name || '';

    if (name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    setLoading(true);
    try {
      await runCreateRealEstate({
        variables: {
          input: {
            ...data,
          },
        },
      });

      toast.success('Imobiliária criada com sucesso!');
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  }

  async function onSubmitConstructionCompany(data: any) {
    const name = data?.name || '';

    if (name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    setLoading(true);
    try {
      await runCreateConstructionCompany({
        variables: {
          input: {
            ...data,
          },
        },
      });

      toast.success('Construtor(a) criada com sucesso!');
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  }

  async function handleDeleteUser(id: string) {
    if (!window.confirm('Realmente deseja cancelar o usuário ?')) return;

    try {
      await runDeleteUser({
        variables: {
          id,
        },
      });

      setUsers(users.filter(u => u.id !== id));
      setTotalUsers(totalUsers - 1);
      setDialogEditUserIsOpen(false);
    } catch (err: any) {
      errorHandler(err);
    }
  }

  async function onSubmitEditRealEstate(data: any) {
    const name = data?.name || '';

    if (name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    setLoading(true);
    try {
      await runUpdateRealEstate({
        variables: {
          input: {
            ...data,
          },
        },
      });

      toast.success('Imobiliária atualizada com sucesso!');
      refreshRealEstate();
      setDialogEditRealEstateIsOpen(false);
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  }

  async function onSubmitEditConstructionCompany(data: any) {
    const name = data?.name || '';

    if (name.length < 3)
      return toast.warn('O nome deve conter no mínimo 3 caracteres');

    setLoading(true);
    try {
      await runUpdateConstructionCompany({
        variables: {
          input: {
            ...data,
          },
        },
      });

      toast.success('Construtor(a) atualizado(a) com sucesso!');
      refreshAllConstructionCompany();
      setDialogEditConstructionCompanyIsOpen(false);
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  }

  if (loadingAllRealEstate || loadingAllConstructionCompany) {
    return <Loading />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container padding={1}>
          <Grid
            container
            paddingX={1}
            spacing={1}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Grid item xs={7} paddingX={1}>
              <TextField
                sx={{ margin: 1 }}
                label="Nome do Usuário"
                fullWidth
                {...register('name')}
              />
            </Grid>
            <Grid item xs={5}>
              <IconButton
                type="submit"
                sx={{
                  background: theme => theme.palette.primary.main,
                }}
              >
                <SearchIcon fontSize="large" color="secondary" />
              </IconButton>
              <IconButton
                sx={{ marginX: 1 }}
                type="button"
                onClick={() => setName(undefined)}
              >
                <ClearIcon fontSize="large" color="secondary" />
              </IconButton>
            </Grid>
          </Grid>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              pageSize={15}
              rowsPerPageOptions={[15]}
              columns={columns}
              rows={users.filter(u => !u.roles.includes('IMOBILIARIA'))}
              isRowSelectable={() => false}
              onRowClick={params => {
                setUserSelected(params.row);
                setDialogEditUserIsOpen(true);
              }}
              onPageChange={page => setPage(page + 1)}
              rowCount={totalUsers}
            />
          </div>

          <div style={{ height: 250, width: '100%', marginTop: '20px' }}>
            <DataGrid
              autoHeight
              hideFooterPagination
              rows={dataAllRealEstate?.allRealEstate || []}
              columns={columnsRealEstate}
              isRowSelectable={() => false}
              onRowClick={params => {
                setEditRealEstateValue('id', Number(params.row.id));
                setEditRealEstateValue('name', params.row.name || '');
                setEditRealEstateValue(
                  'email',
                  params.row.users[0].email || ''
                );
                setEditRealEstateValue('CNPJ', params.row.users[0].CNPJ || '');
                setEditRealEstateValue(
                  'active',
                  String(params.row.active) === 'true'
                );
                setDialogEditRealEstateIsOpen(true);
              }}
            />
          </div>

          <div style={{ height: 250, width: '100%', marginTop: '20px' }}>
            <DataGrid
              autoHeight
              hideFooterPagination
              rows={dataAllConstructionCompany?.allConstructionCompany || []}
              columns={columnsConstructionCompany}
              isRowSelectable={() => false}
              onRowClick={params => {
                setEditConstructionCompanyValue('id', Number(params.row.id));
                setEditConstructionCompanyValue('name', params.row.name || '');
                setEditConstructionCompanyValue(
                  'email',
                  params.row.users[0].email || ''
                );
                setEditConstructionCompanyValue(
                  'CNPJ',
                  params.row.users[0].CNPJ || ''
                );
                setEditConstructionCompanyValue(
                  'active',
                  String(params.row.active) === 'true'
                );
                setDialogEditConstructionCompanyIsOpen(true);
              }}
            />
          </div>

          <Backdrop
            sx={{
              color: theme => theme.palette.primary.main,
              zIndex: theme => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
        </Grid>
      </form>

      <form onSubmit={handleSubmitNewUser(onSubmitNewUser)}>
        <Accordion sx={{ margin: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Cadastrar Usuário</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              label="Nome"
              {...registerNewUserForm('name')}
              required
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerNewUserForm('email')}
              required
            />
            <CPFInput required register={registerNewUserForm} name="CPF" />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="text"
              label="Imobiliária"
              {...registerNewUserForm('realEstate')}
            />
            <FormControl fullWidth sx={{ margin: 1 }}>
              <InputLabel id="select-roles-label">Permissões</InputLabel>
              <Select
                multiple
                labelId="select-roles-label"
                label="Permissões"
                required
                defaultValue={[]}
                {...registerNewUserForm('roles')}
              >
                {Object.values(Role).map(r => (
                  <MenuItem value={r} key={r}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}
            >
              <Button type="submit">Cadastrar</Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </form>
      <form onSubmit={handleSubmitRealEstate(onSubmitRealEstate)}>
        <Accordion sx={{ margin: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Cadastrar Imobiliária</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              label="Nome da Imobiliária"
              {...registerNewRealEstate('name')}
              required
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerNewRealEstate('email')}
              required
            />
            <CNPJInput required register={registerNewRealEstate} name="CNPJ" />
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}
            >
              <Button type="submit">Cadastrar</Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </form>

      <form
        onSubmit={handleSubmitConstructionCompany(onSubmitConstructionCompany)}
      >
        <Accordion sx={{ margin: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Cadastrar Construtor(a)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              label="Nome do(a) Construtor(a)"
              {...registerNewConstructionCompany('name')}
              required
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerNewConstructionCompany('email')}
              required
            />
            <CNPJInput
              required
              register={registerNewConstructionCompany}
              name="CNPJ"
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}
            >
              <Button type="submit">Cadastrar</Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </form>

      <form
        onSubmit={handleSubmitEditConstructionCompany(
          onSubmitEditConstructionCompany
        )}
      >
        <Dialog
          open={dialogEditConstructionCompanyIsOpen}
          onClose={() => setDialogEditConstructionCompanyIsOpen(false)}
          maxWidth={'xs'}
          fullWidth
        >
          <DialogTitle>Editar Construtor(a)</DialogTitle>
          <DialogContent dividers>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={getEditConstructionCompanyValues('active')}
                  onChange={e => {
                    setEditConstructionCompanyValue('active', e.target.checked);
                  }}
                />
              }
              label="Ativo"
              sx={{ margin: 1 }}
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              label="Nome"
              {...registerEditConstructionCompany('name')}
              required
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerEditConstructionCompany('email')}
              required
            />
            <CNPJInput
              required
              register={registerEditConstructionCompany}
              name="CNPJ"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialogEditConstructionCompanyIsOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              onClick={() =>
                buttonEditConstructionCompanySubmitRef.current &&
                buttonEditConstructionCompanySubmitRef?.current?.click()
              }
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <button
          type="submit"
          hidden
          ref={buttonEditConstructionCompanySubmitRef}
        />
      </form>

      <form onSubmit={handleSubmitDialog(onSubmitDialog)}>
        <Dialog
          open={dialogEditUserIsOpen}
          onClose={() => setDialogEditUserIsOpen(false)}
          maxWidth={'xs'}
          fullWidth
        >
          <DialogTitle>Editar Usuário</DialogTitle>
          <DialogContent dividers>
            <Box
              display={'flex'}
              sx={{
                margin: 1,
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleDeleteUser(userSelected?.id || '')}
            >
              <IconButton>
                <DeleteIcon color="error" />
              </IconButton>
              <h4>Deletar</h4>
            </Box>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={userSelected?.active}
                  onChange={e => {
                    setValue('active', e.target.checked);
                  }}
                />
              }
              label="Ativo"
              sx={{ margin: 1 }}
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              label="Nome"
              {...registerDialog('name')}
              required
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerDialog('email')}
              required
            />
            <CPFInput required register={registerDialog} name="CPF" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogEditUserIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                buttonSubmitRef.current && buttonSubmitRef?.current?.click()
              }
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <button type="submit" hidden ref={buttonSubmitRef} />
      </form>

      <form onSubmit={handleSubmitEditRealEstate(onSubmitEditRealEstate)}>
        <Dialog
          open={dialogEditRealEstateIsOpen}
          onClose={() => setDialogEditRealEstateIsOpen(false)}
          maxWidth={'xs'}
          fullWidth
        >
          <DialogTitle>Editar Imobiliária</DialogTitle>
          <DialogContent dividers>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={getEditRealEstateValues('active')}
                  onChange={e => {
                    setEditRealEstateValue('active', e.target.checked);
                  }}
                />
              }
              label="Ativo"
              sx={{ margin: 1 }}
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              label="Nome"
              {...registerEditRealEstate('name')}
              required
            />
            <TextField
              sx={{ margin: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerEditRealEstate('email')}
              required
            />
            <CNPJInput required register={registerEditRealEstate} name="CNPJ" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogEditRealEstateIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                buttonEditRealEstateSubmitRef.current &&
                buttonEditRealEstateSubmitRef?.current?.click()
              }
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <button type="submit" hidden ref={buttonEditRealEstateSubmitRef} />
      </form>
    </>
  );
};

export default Users;
