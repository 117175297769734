import { gql } from '@apollo/client';
import { User } from './entities/user';

interface UsersMinified extends Omit<User, 'roles'> {
  roles: string[];
}

export interface Response {
  realEstate: {
    users: UsersMinified[];
  };
}

export interface Variables {
  id: number;
}

export default gql`
  query ($id: Int) {
    realEstate(id: $id) {
      users {
        id
        name
        CPF
        CNPJ
        email
        active
        roles
      }
    }
  }
`;
