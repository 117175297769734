import { useLazyQuery } from '@apollo/client';
import {
  Add as AddIcon,
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../store/hooks';

// Component
import CPFInput from '../../components/CPFInput';
import DialogSelectConstructionCompany from '../../components/DialogSelectConstructionCompany';
import DialogSelectRealEstate from '../../components/DialogSelectRealEstate';
import DialogSelectUser from '../../components/DialogSelectUser';
import PacsCard from '../../components/PacsCard';

// Utils
import verifyCPF from '../../utils/verifyCPF';
import verifyPermission from '../../utils/verifyPermission';

// Types
import { Pac } from '../../graphql/entities/pac';

// Enums
import { Status } from '../../graphql/entities/pac/status.enum';
import { Type } from '../../graphql/entities/pac/type.enum';
import { Role } from '../../graphql/entities/role/enum';

// Validation
import Validation from './validate';

import { Container } from './styles';

// Query
import findPacs, { Response, Variables } from '../../graphql/findPacs';
import getTeams, { Response as RTeams } from '../../graphql/getTeams';

const Pacs: React.FC = () => {
  const [onlyOperatorPac, setOnlyOperatorPac] = useState(false);
  const userId = useAppSelector(state => state.auth.user?.id);
  const roles = useAppSelector(state => state.auth.user?.roles);
  const { state } = useLocation() as {
    state?: { user?: { name: string; id: string } };
  };
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const [loadPacs] = useLazyQuery<Response>(findPacs, {
    fetchPolicy: 'no-cache',
  });

  const theme = useTheme();
  // Form config
  const { register, handleSubmit } = useForm();

  // Datas
  const [createdAtBegin, setCreatedAtBegin] = useState<Date | null | Moment>(
    null
  );
  const [createdAtEnd, setCreatedAtEnd] = useState<Date | null | Moment>(null);
  const [updatedAtBegin, setUpdatedAtBegin] = useState<Date | null | Moment>(
    ((query.get('updatedAt') && moment(query.get('updatedAt'))) || moment())
      .startOf('month')
      .toDate()
  );
  const [updatedAtEnd, setUpdatedAtEnd] = useState<Date | null | Moment>(
    ((query.get('updatedAt') && moment(query.get('updatedAt'))) || moment())
      .endOf('month')
      .toDate()
  );

  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [userSelected, setUserSelected] = useState([
    state?.user?.id || '',
    state?.user?.name || '',
  ]);
  const [realEstateDialogOpen, setRealEstateDialogOpen] = useState(false);
  const [realEstateSelected, setRealEstateSelected] = useState<
    [string, string]
  >(['', '']);
  const [ConstructionCompanyDialogOpen, setConstructionCompanyDialogOpen] =
    useState(false);
  const [constructionCompanySelected, setConstructionCompanySelected] =
    useState<[string, string]>(['', '']);

  const [pacs, setPacs] = useState<Pac[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [variables, setVariables] = useState<Variables>({
    getPacsInput: {
      page: 1,
      updatedAt: {
        begin: (
          (query.get('updatedAt') && moment(query.get('updatedAt'))) ||
          moment()
        )
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: (
          (query.get('updatedAt') && moment(query.get('updatedAt'))) ||
          moment()
        )
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      status: (query.get('status') as Status) || undefined,
      userId: (state && state.user && state.user.id) || undefined,
    },
  });

  const [teamSelected, setTeamSelected] = useState<number>(0);
  const userInputRef = useRef<HTMLInputElement>(null);
  const realEstateInputRef = useRef<HTMLInputElement>(null);
  const constructionCompanyInputRef = useRef<HTMLInputElement>(null);
  const permission = useMemo(
    () =>
      verifyPermission(
        [
          Role.ADMIN,
          Role.COORDENADOR,
          Role.CORRESPONDENTE,
          Role.GERENTE,
          Role.IMOBILIARIA,
          Role.CONSTRUTOR,
          Role.COLABORADOR,
        ],
        roles || []
      ),
    [roles]
  );

  const [runGetTeams, {data: dataTeams, loading: loadingTeams}] = useLazyQuery<RTeams>(getTeams);

  useEffect(() => {
    if (userInputRef.current) userInputRef.current.value = userSelected[1];
  }, [userSelected]);

  useEffect(() => {
    if (realEstateInputRef.current)
      realEstateInputRef.current.value = realEstateSelected[1];
  }, [realEstateSelected]);

  useEffect(() => {
    if (constructionCompanyInputRef.current)
      constructionCompanyInputRef.current.value =
        constructionCompanySelected[1];
  }, [constructionCompanySelected]);

  useEffect(() => {
    async function getPacs() {
      setLoading(true);

      const { data } = await loadPacs({ variables });

      if (data && data.pacs) {
        setPacs(data.pacs.pacs);
        setTotalPages(data.pacs.totalPages);
      }
      setLoading(false);
    }
    if (variables.getPacsInput) getPacs();
  }, [variables, loadPacs]);

  useEffect(()=> {
    if(verifyPermission([Role.IMOBILIARIA, Role.COORDENADOR], roles || [])){
      runGetTeams();
    }
  }, [roles, runGetTeams]);
  

  const onSubmit = async (data: any) => {
    if (userSelected[1].length > 0) data['userId'] = userSelected[0];
    if (realEstateSelected[1].length > 0)
      data['realEstateId'] = Number(realEstateSelected[0]);
    if (constructionCompanySelected[1].length > 0)
      data['constructionCompanyId'] = Number(constructionCompanySelected[0]);
    if (createdAtBegin || createdAtEnd)
      data['createdAt'] = {
        begin: moment(createdAtBegin).format('YYYY-MM-DD'),
        end: moment(createdAtEnd).format('YYYY-MM-DD'),
      };
    if (updatedAtBegin || updatedAtEnd)
      data['updatedAt'] = {
        begin: moment(updatedAtBegin).format('YYYY-MM-DD'),
        end: moment(updatedAtEnd).format('YYYY-MM-DD'),
      };

    if (onlyOperatorPac) data['operatorId'] = userId;

    if (data.status === 'all') delete data['status'];
    if (data.type === 'all') delete data['type'];
    if (data.name.length === 0) delete data['name'];
    if(teamSelected !== 0) data['teamId'] = teamSelected;
    if (data.cpf.length > 0) {
      if (!verifyCPF(data.cpf)) return toast.error('CPF Inválido!');
    }
    if (data.cpf.length === 0) delete data['cpf'];
    try {
      Validation.validateSync(data);
    } catch (err: any) {
      return toast.error(err?.message);
    }
    data['page'] = 1;

    setVariables({ getPacsInput: data });
  };

  function setPage(page: number) {
    setVariables({ getPacsInput: { ...variables.getPacsInput, page } });
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Accordion sx={{ margin: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SearchIcon sx={{ mr: 1 }} />
            <Typography fontWeight={'bold'}>Busca</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {verifyPermission([Role.CORRESPONDENTE], roles || []) && (
              <Box sx={{ flexGrow: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(_, checked) => setOnlyOperatorPac(checked)}
                    />
                  }
                  label="Visualizar somente as PACs que sou responsável"
                />
              </Box>
            )}

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} padding={1}>
                <Grid item xs={12} sm={12} md={5}>
                  <TextField
                    sx={{ margin: 1 }}
                    label="Nome do Cliente"
                    fullWidth
                    {...register('name')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CPFInput register={register} name="cpf" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl variant="outlined" fullWidth sx={{ margin: 1 }}>
                    <InputLabel id="type-label">Tipo</InputLabel>
                    <Select
                      labelId="type-label"
                      label="Tipo"
                      fullWidth
                      defaultValue={'all'}
                      {...register('type')}
                    >
                      <MenuItem value={'all'}>TODOS</MenuItem>
                      {Object.values(Type).map(t => (
                        <MenuItem
                          key={`${new Date().getTime()}-${t}`}
                          value={t}
                        >
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} padding={1}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl variant="outlined" fullWidth sx={{ margin: 1 }}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      label="Status"
                      fullWidth
                      defaultValue={query.get('status') || 'all'}
                      {...register('status')}
                    >
                      <MenuItem value={'all'}>TODOS</MenuItem>
                      {Object.values(Status)
                        .sort()
                        .map(t => (
                          <MenuItem
                            key={`${new Date().getTime()}-${t}`}
                            value={t}
                          >
                            {t}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {verifyPermission(
                  [Role.ADMIN, Role.CORRESPONDENTE],
                  roles || []
                ) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{ margin: 1 }}
                    >
                      <InputLabel htmlFor="constructionCompany">
                        Construtor(a)
                      </InputLabel>
                      <OutlinedInput
                        inputRef={constructionCompanyInputRef}
                        disabled={constructionCompanySelected[0].length > 0}
                        label="Construtor(a)"
                        name="constructionCompany"
                        sx={{ paddingRight: 3 }}
                        endAdornment={
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Buscar Construtor(a)"
                                edge="end"
                                onClick={() => {
                                  if (
                                    constructionCompanyInputRef &&
                                    constructionCompanyInputRef?.current &&
                                    constructionCompanyInputRef.current?.value
                                      .length < 3
                                  )
                                    return toast.warn(
                                      'Digite pelo menos 3 caracteres.'
                                    );

                                  setConstructionCompanyDialogOpen(true);
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Limpar"
                                edge="end"
                                onClick={() =>
                                  setConstructionCompanySelected(['', ''])
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          </>
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
                {verifyPermission(
                  [
                    Role.ADMIN,
                    Role.CORRESPONDENTE,
                    Role.CONSTRUTOR,
                    Role.COLABORADOR,
                  ],
                  roles || []
                ) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{ margin: 1 }}
                    >
                      <InputLabel htmlFor="realEstate">Imobiliária</InputLabel>
                      <OutlinedInput
                        inputRef={realEstateInputRef}
                        disabled={realEstateSelected[0].length > 0}
                        label="Imobiliária"
                        name="realEstate"
                        sx={{ paddingRight: 3 }}
                        endAdornment={
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Buscar Imobiliária"
                                edge="end"
                                onClick={() => {
                                  if (
                                    realEstateInputRef &&
                                    realEstateInputRef?.current &&
                                    realEstateInputRef.current?.value.length < 3
                                  )
                                    return toast.warn(
                                      'Digite pelo menos 3 caracteres.'
                                    );

                                  setRealEstateDialogOpen(true);
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Limpar"
                                edge="end"
                                onClick={() => setRealEstateSelected(['', ''])}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          </>
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
                {
                  verifyPermission([Role.IMOBILIARIA, Role.COORDENADOR], roles || []) && (
                    <Grid item xs={12} sm={6} md={3}>
                    <FormControl variant="outlined" fullWidth sx={{ margin: 1 }}>
                    <InputLabel id="type-label">Equipe</InputLabel>
                    <Select
                      labelId="type-label"
                      label="Equipe"
                      fullWidth
                      defaultValue={0}
                      onChange={(e)=> setTeamSelected(Number(e.target.value))}
                    >
                      <MenuItem value={0}>TODAS</MenuItem>
                      {dataTeams?.teams.map(t => (
                        <MenuItem
                          key={`${new Date().getTime()}-${t.name}-${t.id}`}
                          value={t.id}
                        >
                          {t.name} - Gerente: {t.owner.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  )
                }
                {permission && (
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{ margin: 1 }}
                      disabled={teamSelected !== 0}
                    >
                      <InputLabel htmlFor="user">Responsável</InputLabel>
                      <OutlinedInput
                        inputRef={userInputRef}
                        disabled={userSelected[0].length > 0 || teamSelected !== 0}
                        label="Responsável"
                        name="user"
                        sx={{ paddingRight: 3 }}
                        endAdornment={
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Buscar responsável"
                                edge="end"
                                onClick={() => {
                                  if (
                                    userInputRef &&
                                    userInputRef?.current &&
                                    userInputRef.current?.value.length < 3
                                  )
                                    return toast.warn(
                                      'Digite pelo menos 3 caracteres.'
                                    );

                                  setUserDialogOpen(true);
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Limpar"
                                edge="end"
                                onClick={() => setUserSelected(['', ''])}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          </>
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Divider />

            <Box>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                style={{}}
                sx={{
                  padding: 1,
                  flexGrow: 1,
                  flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                <Grid container spacing={2} padding={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight="bold" marginBottom={1}>
                      Data de Criação
                    </Typography>
                    <Grid container spacing={2} padding={1}>
                      <Grid item sx={{ textAlign: 'center' }} xs={12} sm={6}>
                        <LocalizationProvider
                          adapterLocale={'pt-br'}
                          dateAdapter={AdapterMoment}
                        >
                          <MobileDatePicker
                            label="De"
                            inputFormat="DD/MM/yyyy"
                            value={createdAtBegin}
                            maxDate={moment(createdAtEnd) || undefined}
                            onChange={v => setCreatedAtBegin(v)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="Limpar"
                                        edge="end"
                                        onClick={() => setCreatedAtBegin(null)}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item sx={{ textAlign: 'center' }} xs={12} sm={6}>
                        <LocalizationProvider
                          adapterLocale={'pt-br'}
                          dateAdapter={AdapterMoment}
                        >
                          <MobileDatePicker
                            label="Até"
                            inputFormat="DD/MM/yyyy"
                            value={createdAtEnd}
                            minDate={moment(createdAtBegin) || undefined}
                            onChange={v => setCreatedAtEnd(v)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="Limpar"
                                        edge="end"
                                        onClick={() => setCreatedAtEnd(null)}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 0, md: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      fontWeight="bold"
                      marginBottom={{ xs: 2, md: 1 }}
                    >
                      Última Atualização
                    </Typography>
                    <Grid container spacing={2} padding={1}>
                      <Grid item sx={{ textAlign: 'center' }} xs={12} sm={6}>
                        <LocalizationProvider
                          adapterLocale={'pt-br'}
                          dateAdapter={AdapterMoment}
                        >
                          <MobileDatePicker
                            label="De"
                            inputFormat="DD/MM/yyyy"
                            value={updatedAtBegin}
                            maxDate={moment(updatedAtEnd) || undefined}
                            onChange={v => setUpdatedAtBegin(v)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="Limpar"
                                        edge="end"
                                        onClick={() => setUpdatedAtBegin(null)}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item sx={{ textAlign: 'center' }} xs={12} sm={6}>
                        <LocalizationProvider
                          adapterLocale={'pt-br'}
                          dateAdapter={AdapterMoment}
                        >
                          <MobileDatePicker
                            label="Até"
                            inputFormat="DD/MM/yyyy"
                            value={updatedAtEnd}
                            minDate={moment(updatedAtBegin) || null}
                            onChange={v => setUpdatedAtEnd(v)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="Limpar"
                                        edge="end"
                                        onClick={() => setUpdatedAtEnd(null)}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  type="submit"
                  style={{ background: theme.palette.primary.main }}
                >
                  <SearchIcon fontSize="large" color="secondary" />
                </IconButton>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </form>

      {verifyPermission(
        [Role.ADMIN, Role.CORRESPONDENTE, Role.CONSTRUTOR, Role.COLABORADOR],
        roles || []
      ) && (
        <DialogSelectRealEstate
          name={realEstateInputRef.current?.value || ''}
          open={realEstateDialogOpen}
          setIsOpen={setRealEstateDialogOpen}
          setRealEstate={setRealEstateSelected}
        />
      )}

      {verifyPermission([Role.ADMIN, Role.CORRESPONDENTE], roles || []) && (
        <DialogSelectConstructionCompany
          name={constructionCompanyInputRef.current?.value || ''}
          open={ConstructionCompanyDialogOpen}
          setIsOpen={setConstructionCompanyDialogOpen}
          setConstructionCompany={setConstructionCompanySelected}
        />
      )}

      {permission && (
        <DialogSelectUser
          name={userInputRef.current?.value || ''}
          open={userDialogOpen}
          setIsOpen={setUserDialogOpen}
          setUser={setUserSelected}
          realEstateId={
            Number(realEstateSelected[0]) !== 0
              ? Number(realEstateSelected[0])
              : undefined
          }
        />
      )}

      <Backdrop
        sx={{
          color: theme => theme.palette.secondary.main,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loading || loadingTeams}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <PacsCard
          pacs={pacs}
          page={variables.getPacsInput?.page || 1}
          totalPages={totalPages}
          setPage={setPage}
        />
      )}
      <Fab
        onClick={() => navigate('/pacs/criar')}
        color="secondary"
        arial-label="criar pac"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
};

export default Pacs;
