import { useLazyQuery } from '@apollo/client';
import { Person } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

// import { Container } from './styles';

// Query
import getAllRealEstate, {
  Response as RAllRealEstate,
  Variables as VAllRealEstate,
} from '../../graphql/allRealEstate';

interface Props {
  name: string;
  open?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRealEstate: React.Dispatch<React.SetStateAction<[string, string]>>;
}

const DialogSelectRealEstate: React.FC<Props> = ({
  name,
  open = false,
  setIsOpen,
  setRealEstate,
}) => {
  const [selectedRealEstate, setSelectedRealEstate] = useState<
    [string, string]
  >(['', '']);

  const [loadRealEstate, { data, loading }] = useLazyQuery<
    RAllRealEstate,
    VAllRealEstate
  >(getAllRealEstate);

  useEffect(() => {
    if (open)
      loadRealEstate({
        variables: { findAllRealEstateInput: { name, active: true } },
      });
  }, [loadRealEstate, name, open]);

  const handleConfirm = () => {
    setRealEstate(selectedRealEstate);
    setIsOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        <Typography
          fontWeight="bold"
          alignItems="center"
          sx={{ display: 'flex' }}
          fontSize="large"
        >
          <Person fontSize="medium" sx={{ marginRight: 1 }} />
          Selecione uma imobiliária
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <>
          {loading && <Skeleton animation="wave" />}
          {!loading && (
            <List>
              {data &&
                data?.allRealEstate.length > 0 &&
                data?.allRealEstate.map(u => (
                  <ListItemButton
                    key={u.id}
                    selected={u?.id === selectedRealEstate[0]}
                    onClick={() =>
                      setSelectedRealEstate([u?.id || '', u?.name || ''])
                    }
                  >
                    <ListItemText>{u.name}</ListItemText>
                  </ListItemButton>
                ))}
            </List>
          )}
        </>
        {data && data?.allRealEstate.length <= 0 && !loading && (
          <Typography textAlign="center" align="center" fontWeight="bold">
            Nenhuma Imobiliária Encontrada!
          </Typography>
        )}
        <Divider sx={{ marginTop: 2 }} />
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>SAIR</Button>
          {selectedRealEstate[0].length > 0 && (
            <Button onClick={handleConfirm}>CONFIRMAR</Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSelectRealEstate;
