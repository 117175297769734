import { gql } from '@apollo/client';
import { Role } from './entities/role/enum';

export interface Response {}

export interface Variables {
  createUserInput: {
    name: string;
    CPF: string;
    email: string;
    realEstate: string;
    roles: Role[];
  };
}

export default gql`
  mutation ($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
    }
  }
`;
