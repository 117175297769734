import { gql } from '@apollo/client';

export interface Response {}

export default gql`
  mutation updateDocument($id: Int!, $approved: Boolean, $protected: Boolean) {
    updateDocument(
      updateDocument: { id: $id, approved: $approved, protected: $protected }
    ) {
      id
    }
  }
`;
