import { gql } from '@apollo/client';
import { Status } from './entities/pac/status.enum';

export type Response = any;

export interface Variables {
  userId?: string;
  updatedAtBegin: string;
  updatedAtEnd: string;
}

const query = Object.entries(Status).reduce(
  (r, n) => `${r}
${n[0]}: pacs(getPacsInput: {
  updatedAt:{
    begin: $updatedAtBegin
    end: $updatedAtEnd
  }, 
  status: "${n[1]}",
  userId: $userId,
}){
  totalPacs
}`,
  ''
);

export default gql`
  query($updatedAtBegin: String!, $updatedAtEnd: String!, $userId: String)  {
    ${query}
  }
`;
