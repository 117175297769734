import { gql } from '@apollo/client';

export interface Response {
  createConstructionCompany: {
    id: string;
  };
}

export interface Variables {
  input: {
    name: string;
    email: string;
    CNPJ: string;
  };
}

export default gql`
  mutation ($input: CreateConstructionCompanyInput!) {
    createConstructionCompany(createConstructionCompanyInput: $input) {
      id
    }
  }
`;
