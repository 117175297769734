import { useLazyQuery, useMutation } from '@apollo/client';
import { Person } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// import { Container } from './styles';

// Utils
import errorHandler from '../../utils/errorHandler';

// Query
import getAllConstructionCompany, {
  Response as RAllConstructionCompany,
  Variables as VAllConstructionCompany,
} from '../../graphql/allConstructionCompany';

// Mutation
import bindPacConstructionCompanyMutation, {
  Response as ResponseBindPacConstructionCompany,
  Variables as VariablesBindPacConstructionCompany,
} from '../../graphql/bindPacConstructionCompanyMutation';

interface Props {
  name?: string;
  open?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setConstructionCompany?: React.Dispatch<
    React.SetStateAction<[string, string]>
  >;
  pacId?: number;
}

const DialogSelectConstructionCompany: React.FC<Props> = ({
  name,
  open = false,
  setIsOpen,
  setLoading,
  pacId,
  setConstructionCompany,
}) => {
  const navigate = useNavigate();

  const [selectedConstructionCompany, setSelectedConstructionCompany] =
    useState<[string, string]>(['', '']);

  const [runBindPacConstructionCompany] = useMutation<
    ResponseBindPacConstructionCompany,
    VariablesBindPacConstructionCompany
  >(bindPacConstructionCompanyMutation);

  const [loadConstructionCompany, { data, loading }] = useLazyQuery<
    RAllConstructionCompany,
    VAllConstructionCompany
  >(getAllConstructionCompany);

  useEffect(() => {
    if (open)
      loadConstructionCompany({
        variables: { findAllConstructionCompanyInput: { name, active: true } },
      });
  }, [loadConstructionCompany, name, open]);

  const handleConfirm = async () => {
    if (!pacId) {
      if (setConstructionCompany) {
        setConstructionCompany(selectedConstructionCompany);
      }
      setIsOpen(false);
      return;
    }

    if (setLoading) setLoading(true);
    try {
      await runBindPacConstructionCompany({
        variables: {
          input: {
            id: pacId,
            constructionCompanyId: Number(selectedConstructionCompany[0]),
          },
        },
      });

      if (setLoading) setLoading(false);
      navigate(0);
      toast.success('Construtor(a) Vinculado a PAC!');
    } catch (err) {
      if (setLoading) setLoading(false);
      errorHandler(err);
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        <Typography
          fontWeight="bold"
          alignItems="center"
          sx={{ display: 'flex' }}
          fontSize="large"
        >
          <Person fontSize="medium" sx={{ marginRight: 1 }} />
          Selecione um(a) Construtor(a)
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <>
          {loading && <Skeleton animation="wave" />}
          {!loading && (
            <List>
              {data &&
                data?.allConstructionCompany.length > 0 &&
                data?.allConstructionCompany.map(u => (
                  <ListItemButton
                    key={u.id}
                    selected={u?.id === selectedConstructionCompany[0]}
                    onClick={() =>
                      setSelectedConstructionCompany([
                        u?.id || '',
                        u?.name || '',
                      ])
                    }
                  >
                    <ListItemText>{u.name}</ListItemText>
                  </ListItemButton>
                ))}
            </List>
          )}
        </>
        {data && data?.allConstructionCompany.length <= 0 && !loading && (
          <Typography textAlign="center" align="center" fontWeight="bold">
            Nenhum(a) Construtor(a) Encontrado(a)!
          </Typography>
        )}
        <Divider sx={{ marginTop: 2 }} />
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>SAIR</Button>
          {selectedConstructionCompany[0].length > 0 && (
            <Button onClick={handleConfirm}>CONFIRMAR</Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSelectConstructionCompany;
