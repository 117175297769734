import { gql } from '@apollo/client';

export interface Response {
  updateTeam: any;
}

export interface Variables {
  updateTeamInput: {
    id: number;
    name: String;
    ownerId: String;
  };
}

export default gql`
  mutation updateTeam($updateTeamInput: UpdateTeamInput!) {
    updateTeam(updateTeamInput: $updateTeamInput) {
      name
    }
  }
`;
