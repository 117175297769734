import { Container as UIContainer, ContainerProps } from '@mui/material';
import { styled as UIStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { themeStyledComponent } from '../../styles/theme';

export const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;

  background-color: ${themeStyledComponent.primary};
`;

export const FormContainer = UIStyled(UIContainer)<ContainerProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: themeStyledComponent.white,
    border: `1px solid ${themeStyledComponent.white}`,
    borderRadius: 15,
    padding: 30,
    zIndex: 1,
    minHeight: 262,
  })
);

export const Mone = styled.img`
  width: 300px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 0;
  z-index: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 125px;
  margin: 15px;
  margin-bottom: 30px;
`;
