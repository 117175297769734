import { gql } from '@apollo/client';
import { RoleRealEstate } from './entities/role/enum';

export interface Response {
  addUserRealEstate: {
    id: string;
  };
}

export interface Variables {
  input: {
    name: string;
    CPF: string;
    roles: RoleRealEstate[];
    email: string;
  };
}

export default gql`
  mutation ($input: AddUserRealEstateInput!) {
    addUserRealEstate(addUserRealEstateInput: $input) {
      id
    }
  }
`;
