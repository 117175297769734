import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#55178F',
    },
    secondary: { main: '#Ec9323' },
    error: { main: red.A400 },
  },
});

export const themeStyledComponent = {
  primary: '#55178F',
  secondary: '#Ec9323',
  third: '#3BB554',
  white: '#FFF',
  gray: '#E5E5E5',
  black: '#000',
};

export default theme;
