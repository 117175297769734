import { gql } from '@apollo/client';

export interface Response {
  unbindPacConstructionCompany: {
    id: number;
  };
}

export interface Variables {
  id: number;
}

export default gql`
  mutation ($id: Int!) {
    unbindPacConstructionCompany(id: $id) {
      id
    }
  }
`;
