import { gql } from '@apollo/client';

export interface Response {
  updateConstructionCompany: {
    id: string;
  };
}

export interface Variables {
  input: {
    id: string;
    name?: string;
    CNPJ?: string;
    email?: string;
    active?: boolean;
  };
}

export default gql`
  mutation ($input: UpdateConstructionCompanyInput!) {
    updateConstructionCompany(updateConstructionCompanyInput: $input) {
      id
    }
  }
`;
