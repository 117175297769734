import React from "react";

import { useQuery } from "@apollo/client";

import {
  Avatar,
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

// Queries
import getTeam, { Response, Variables } from "../../graphql/myTeamsMutation";

// Utils
import avatarGenerate from "../../utils/avatarGenerate";
const MyTeam: React.FC = () => {
  // Queries

  const {
    data: dataTeam,
    loading: loadingTeam,
    error,
  } = useQuery<Response, Variables>(getTeam);

  let data = { teams: dataTeam?.myteams || [] };

  if (error) {
    toast.error("Equipe não encontrada", { toastId: 297 });
    return <Navigate to="/" />;
  }

  return (
    <main>
      {data?.teams?.map((t) => (
        <Grid container spacing={2} padding={2} key={t.id}>
          <Grid
            item
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            xs={12}
            gap={4}
          >
            <Avatar
              {...avatarGenerate(t.name || "")}
              style={{ width: 80, height: 80 }}
            />
            <div>
              <Typography
                component="p"
                variant="h4"
                color="text.primary"
                textAlign="center"
              >
                {t.name}
              </Typography>
              <Typography
                component="p"
                variant="body2"
                color="text.primary"
                textAlign="center"
              >
                Gerente: {t.owner.name}
              </Typography>
            </div>
            <div></div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h4">
              Membros
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {t.members && t.members.length < 1 && (
                <Typography textAlign="center" variant="body1">
                  A equipe não possui membros!
                </Typography>
              )}
              {t.members.map((m) => (
                <ListItemButton sx={{ maxWidth: 300, width: 300 }}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar {...avatarGenerate(m.name || "")} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "block",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "130px",
                            }}
                            component="p"
                            variant="body2"
                            color="text.primary"
                            fontWeight="500"
                          >
                            {m.name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={m.roles
                        ?.map(
                          (r) =>
                            r.name.charAt(0).toUpperCase() + r.name.slice(1)
                        )
                        .join(",")}
                    />
                  </ListItem>
                </ListItemButton>
              ))}
            </List>
          </Grid>
        </Grid>
      ))}
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingTeam}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </main>
  );
};

export default MyTeam;
