import { Navigate, Route, Routes as Router } from "react-router-dom";
import Admin from "../pages/Admin";
import ConstructionCompany from "../pages/ConstructionCompany";
import CreatePac from "../pages/CreatePac";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import MyTeam from "../pages/MyTeam";
import Pac from "../pages/Pac";
import Pacs from "../pages/Pacs";
import RealEstate from "../pages/RealEstate";
import RecoveryPassword from "../pages/RecoveryPassword";
import Team from "../pages/Team";
import AuthRoute from "./AuthRoute";

const Routes: React.FC = () => (
  <Router>
    <Route path="/login" element={<Login />} />
    <Route path="/recuperar" element={<RecoveryPassword />} />
    <Route
      path="/"
      element={
        <AuthRoute>
          <Dashboard />
        </AuthRoute>
      }
    />
    <Route
      path="/pacs"
      element={
        <AuthRoute>
          <Pacs />
        </AuthRoute>
      }
    />
    <Route
      path="/pacs/criar"
      element={
        <AuthRoute>
          <CreatePac />
        </AuthRoute>
      }
    />
    <Route
      path="/pac/:id"
      element={
        <AuthRoute>
          <Pac />
        </AuthRoute>
      }
    />
    <Route
      path="/imobiliaria/equipe/:id"
      element={
        <AuthRoute>
          <Team />
        </AuthRoute>
      }
    />
    <Route
      path="/imobiliaria/minhasequipes"
      element={
        <AuthRoute>
          <MyTeam />
        </AuthRoute>
      }
    />
    {/* <Route
      path="/configuracoes"
      element={
        <AuthRoute>
          <Configs />
        </AuthRoute>
      }
    /> */}
    <Route
      path="/admin"
      element={
        <AuthRoute>
          <Admin />
        </AuthRoute>
      }
    />
    <Route
      path="/imobiliaria"
      element={
        <AuthRoute>
          <RealEstate />
        </AuthRoute>
      }
    />
    <Route
      path="/construtora"
      element={
        <AuthRoute>
          <ConstructionCompany />
        </AuthRoute>
      }
    />
    <Route path="*" element={<Navigate to="/" />} />
  </Router>
);

export default Routes;
