import { gql } from '@apollo/client';

export interface Response {}

export interface Variables {
  id: string;
}

export default gql`
  mutation ($id: String!) {
    removeUser(deleteUserInput: { id: $id })
  }
`;
