import { gql } from "@apollo/client";
import { User } from "./entities/user";

export interface Response {
  getUsers: {
    users: User[];
    page: number;
    totalPages: number;
    usersInPage: number;
    totalUsers: number;
  };
}

export interface Variables {
  getUsersInput: {
    page: number;
    name?: string;
    realEstateId?: number;
    active?: boolean;
  };
}

export default gql`
  query usersFindToPac($getUsersInput: GetUsersInput!) {
    getUsers(getUsersInput: $getUsersInput) {
      users {
        id
        name
        CPF
        email
        active
        roles {
          name
        }
        realEstate {
          name
        }
      }
      page
      totalPages
      usersInPage
      totalUsers
    }
  }
`;
