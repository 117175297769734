import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { reset } from '../store/modules/auth/authSlice';
import { Context } from '../store/SocketContext';

// Layouts
import Layout from '../styles/layout/Auth';

interface Props {
  children?: React.ReactNode;
}

const AuthRoute: React.FC<Props> = ({ children }) => {
  const { socket } = useContext(Context);
  const location = useLocation();
  const logged = useAppSelector(state => state.auth.logged);
  const expiresIn = useAppSelector(state => state.auth.expiresIn);
  const user = useAppSelector(state => state.auth.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (moment().isAfter(moment(expiresIn))) {
      dispatch(reset());
      toast.warn('Sua sessão expirou! Por favor, entre novamente.', {
        toastId: 1,
      });
    }
  }, [dispatch, expiresIn]);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken') || '';
    if (!socket.connected && logged && user?.id) {
      socket.connect();
    }
    socket.on('getUserId', () => {
      socket.emit('login', user?.id);
      if (userToken.length > 0)
        socket.emit('firebaseNotificationId', {
          firebase: userToken,
          userId: user?.id,
        });
    });
  }, [logged, user?.id, socket]);

  if (!logged) {
    return (
      <Navigate to={`/login?backUrl=${location.pathname}${location.search}`} />
    );
  }

  let query: any = new URLSearchParams(location.search);

  if (query.get('backUrl')) {
    return <Navigate to={query.get('backUrl')} />;
  }

  return <Layout>{children}</Layout>;
};

export default AuthRoute;
