import { gql } from '@apollo/client';
import { RoleConstructionCompany } from './entities/role/enum';

export interface Response {
  addUserConstructionCompany: {
    id: string;
  };
}

export interface Variables {
  input: {
    name: string;
    CPF: string;
    roles: RoleConstructionCompany[];
    email: string;
  };
}

export default gql`
  mutation ($input: AddUserConstructionCompanyInput!) {
    addUserConstructionCompany(addUserConstructionCompanyInput: $input) {
      id
    }
  }
`;
