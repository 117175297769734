import { useMutation } from '@apollo/client';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  NewReleases as NewIcon,
} from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Notification as INotification } from '../../graphql/entities/notifications';
import checkNotification, { Response } from '../../graphql/notificationCheck';
import checkAllNotifications, {
  Response as ResponseCheckAll,
} from '../../graphql/notificationCheckAll';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  notifications?: [] | INotification[];
  setNotifications: React.Dispatch<React.SetStateAction<INotification[]>>;
}

const FullScreenDialog: React.FC<Props> = ({
  open,
  handleClose,
  notifications,
  setNotifications,
}) => {
  const [check] = useMutation<Response>(checkNotification);
  const [checkAll] = useMutation<ResponseCheckAll>(checkAllNotifications);

  const handleNotification = async (id: number, toUrl: string = '#') => {
    if (!notifications) return;
    const index = notifications.findIndex(n => n.id === id);
    try {
      if (!notifications[index].checked) {
        await check({ variables: { id: Number(id) } });
        setNotifications(
          notifications?.map(n => {
            if (n.id === id) return { ...n, checked: true };
            return n;
          })
        );
      }
    } catch (err) {}
    window.location.replace(toUrl);
  };

  const handleCheckAll = async () => {
    try {
      await checkAll();
      setNotifications(
        notifications?.map(n => ({ ...n, checked: true })) || []
      );
    } catch (err) {}
    handleClose(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => handleClose(false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Notificações
          </Typography>
          <Button autoFocus color="inherit" onClick={handleCheckAll}>
            Marcar Todas como Lidas
          </Button>
        </Toolbar>
      </AppBar>
      <List>
        {notifications &&
          notifications.map(n => (
            <span key={`${new Date().getTime()}-${n.id}`}>
              <ListItem
                button
                onClick={() => handleNotification(n.id, n.toUrl)}
              >
                <ListItemText primary={n.title} secondary={n.content} />
                {n.checked ? (
                  <CheckIcon color="success" />
                ) : (
                  <NewIcon color="error" />
                )}
              </ListItem>
              <Divider />
            </span>
          ))}
      </List>
    </Dialog>
  );
};

export default FullScreenDialog;
