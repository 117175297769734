import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { User } from '../../../graphql/entities/user';

export interface AuthState {
  logged: boolean;
  token?: string;
  expiresIn?: string;
  user?: User;
}

const initialState: AuthState = {
  logged: false,
  token: undefined,
  expiresIn: undefined,
  user: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
      state.expiresIn =
        action.payload === undefined
          ? undefined
          : moment().add(1, 'day').toISOString();
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
    setLogged: (state, action: PayloadAction<boolean>) => {
      state.logged = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setToken, setLogged, setUser, reset } = authSlice.actions;

export default authSlice.reducer;
