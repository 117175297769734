import * as yup from 'yup';

export default yup.object({
  nameClient: yup
    .string()
    .trim()
    .required()
    .min(3, 'O nome do cliente deve ter pelo menos 3 caracteres'),
  nameConjuge: yup
    .string()
    .trim()
    .optional()
    .min(3, 'O nome do cliente deve ter pelo menos 3 caracteres'),
  CPFClient: yup.string().trim().required().min(14, 'CPF do Cliente inválido'),
  CPFConjuge: yup.string().trim().optional().min(14, 'CPF do Cônjuge inválido'),
  product: yup
    .string()
    .trim()
    .required()
    .min(3, 'O produto deve ter pelo menos 3 caracteres'),
  emailClient: yup.string().email().trim().required(),
  phoneClient: yup.string().trim().required(),
});
