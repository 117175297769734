import { useLazyQuery } from '@apollo/client';
import { Person } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

// import { Container } from './styles';

// Query
import usersFindToPac, { Response } from '../../graphql/usersFindToPacQuery';

interface Props {
  name: string;
  realEstateId?: number;
  open?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<string[]>>;
}

const DialogSelectUser: React.FC<Props> = ({
  name,
  open = false,
  setIsOpen,
  setUser,
  realEstateId,
}) => {
  const [selectedUser, setSelectedUser] = useState(['', '']);
  const [page, setPage] = useState(1);

  const [loadUsers, { data, loading }] = useLazyQuery<Response>(usersFindToPac);

  useEffect(() => {
    if (open) loadUsers({ variables: { page, name, realEstateId } });
  }, [page, loadUsers, name, open, realEstateId]);

  const handleConfirm = () => {
    setUser(selectedUser);
    setIsOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        <Typography
          fontWeight="bold"
          alignItems="center"
          sx={{ display: 'flex' }}
          fontSize="large"
        >
          <Person fontSize="medium" sx={{ marginRight: 1 }} />
          Selecione um usuário
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <>
          {loading && <Skeleton animation="wave" />}
          {!loading && (
            <List>
              {data &&
                data?.getUsers.users.length > 0 &&
                data?.getUsers.users.map(u => (
                  <ListItemButton
                    key={u.id}
                    selected={u?.id === selectedUser[0]}
                    onClick={() =>
                      setSelectedUser([u?.id || '', u?.name || ''])
                    }
                  >
                    <ListItemText>{u.name}</ListItemText>
                  </ListItemButton>
                ))}
            </List>
          )}
          {data && data?.getUsers.users.length > 0 && (
            <Stack
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <Pagination
                onChange={(_, p) => setPage(p)}
                count={data.getUsers.totalPages}
              />
            </Stack>
          )}
        </>
        {data && data?.getUsers.users.length <= 0 && !loading && (
          <Typography textAlign="center" align="center" fontWeight="bold">
            Nenhum Usuário Encontrado!
          </Typography>
        )}
        <Divider sx={{ marginTop: 2 }} />
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>SAIR</Button>
          {selectedUser[0].length > 0 && (
            <Button onClick={handleConfirm}>CONFIRMAR</Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSelectUser;
