import { gql } from '@apollo/client';

export interface Response {
  insertTeamUsers: Boolean;
}

export interface Variables {
  insertTeamUserInput: {
    teamId: number;
    users: string[];
  };
}

export default gql`
  mutation insertTeamUsers($insertTeamUserInput: InsertTeamUserInput!) {
    insertTeamUsers(insertTeamUserInput: $insertTeamUserInput)
  }
`;
