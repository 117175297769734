import React, { useRef, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  AddCircle as AddCircleIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Role, RoleRealEstate } from "../../graphql/entities/role/enum";
import { User } from "../../graphql/entities/user";
import { useAppSelector } from "../../store/hooks";

// Utils
import avatarGenerate from "../../utils/avatarGenerate";
import errorHandler from "../../utils/errorHandler";
import verifyCPF from "../../utils/verifyCPF";

// Components
import CPFInput from "../../components/CPFInput";

// Queries
import getUsersRealEstate, {
  Response,
  Variables,
} from "../../graphql/getUsersRealEstate";

import getTeams, { Response as RTeams } from "../../graphql/getTeams";

// Mutations
import addUserRealEstate, {
  Response as RAddUser,
  Variables as VAddUser,
} from "../../graphql/addUserRealEstate";

import createTeamMutation, {
  Response as RCreateTeam,
  Variables as VCreateTeam,
} from "../../graphql/createTeamMutation";

import updateUserRealEstate, {
  Response as RUpdateEstate,
  Variables as VUpdateEstate,
} from "../../graphql/updateUserRealEstate";

const transformData = (users: Response["realEstate"]["users"] | undefined) => {
  if (users === undefined) return [];
  return users.map((u) => {
    return {
      ...u,
      documentId: u.CPF || u.CNPJ,
      active: String(u.active) === "true" ? "Sim" : "Não",
      roles: u.roles
        .map((u: string) => u.charAt(0).toLocaleUpperCase() + u.slice(1))
        .join(", "),
    };
  });
};

const columns: GridColDef[] = [
  { field: "name", headerName: "Nome", width: 400 },
  { field: "documentId", headerName: "CPF/CNPJ", width: 150 },
  { field: "email", headerName: "Email", width: 400 },
  { field: "roles", headerName: "Cargo", width: 300 },
  { field: "active", headerName: "Ativo", width: 70, flex: 1 },
];

const Loading: React.FC = () => (
  <Box margin={5}>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={118}
      sx={{ margin: 1 }}
    />
    <Skeleton sx={{ margin: 1 }} variant="rectangular" animation="wave" />
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5, margin: 1 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={118}
      sx={{ margin: 1 }}
    />
  </Box>
);

const RealEstate: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dialogEditUserIsOpen, setDialogEditUserIsOpen] = useState(false);
  const [dialogCreateTeamIsOpen, setDialogCreateTeamIsOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | null | undefined>(
    null
  );
  const [onlyActive, setOnlyActive] = useState(false);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const buttonSubmitCreateTeamRef = useRef<HTMLButtonElement>(null);

  const { register: registerCreateTeam, handleSubmit: handleSubmitCreateTeam } =
    useForm();

  const {
    register: registerEditUserDialog,
    handleSubmit: handleEditUserSubmitDialog,
    setValue,
    getValues,
  } = useForm();
  const { register: registerAddUser, handleSubmit: handleSubmitAddUser } =
    useForm();

  const realEstate = useAppSelector((state) => state.auth.user?.realEstate);
  const {
    data,
    loading: loadingQuery,
    refetch,
  } = useQuery<Response, Variables>(getUsersRealEstate, {
    variables: { id: Number(realEstate?.id) || 0 },
  });

  const {
    data: dataTeams,
    loading: loadingTeams,
    refetch: refetchTeams,
  } = useQuery<RTeams>(getTeams);

  const [runAddUser] = useMutation<RAddUser, VAddUser>(addUserRealEstate);
  const [runUpdateUserRealEstate] = useMutation<RUpdateEstate, VUpdateEstate>(
    updateUserRealEstate
  );
  const [runCreateTeam] = useMutation<RCreateTeam, VCreateTeam>(
    createTeamMutation
  );

  const onSubmitAddUser = async (data: any) => {
    let typedData: VAddUser["input"] = data as VAddUser["input"];

    if (typedData.name.length < 3)
      return toast.warn("O nome deve conter no mínimo 3 caracteres");

    if (!verifyCPF(typedData.CPF)) return toast.warn("CPF Inválido");

    if (data.roles.length === 0) return toast.warn("Selecione uma permissão");

    setLoading(true);
    try {
      await runAddUser({
        variables: {
          input: typedData,
        },
      });

      toast.success("Usuário criado com sucesso!");
    } catch (err: any) {
      errorHandler(err);
    }
    setLoading(false);
  };

  const onSubmitCreateTeam = async (data: any) => {
    let typedData: VCreateTeam["createTeamInput"] =
      data as VCreateTeam["createTeamInput"];

    if (typedData.name && typedData.name.length < 3)
      return toast.warn("O nome da equipe deve conter no mínimo 3 caracteres");

    if (typedData.ownerId && typedData.ownerId.length <= 0)
      return toast.warn("Selecione um gerente para a equipe");

    setLoading(true);

    try {
      await runCreateTeam({
        variables: {
          createTeamInput: typedData,
        },
      });

      toast.success("Equipe criada com sucesso!");
      refetchTeams();
    } catch (err: any) {
      errorHandler(err);
    }
    setDialogCreateTeamIsOpen(false);
    setLoading(false);
  };

  const onSubmitEditUser = async (data: any) => {
    if (typeof data.roles === "string") data.roles = data.roles.split(",");
    let typedData: VUpdateEstate["input"] = data as VUpdateEstate["input"];

    if (typedData?.name && typedData.name.length < 3)
      return toast.warn("O nome deve conter no mínimo 3 caracteres");

    if (typedData?.CPF && !verifyCPF(typedData.CPF))
      return toast.warn("CPF Inválido");

    if (typedData?.roles && typedData?.roles.length === 0)
      return toast.warn("Selecione uma permissão");

    setLoading(true);
    try {
      await runUpdateUserRealEstate({
        variables: {
          input: typedData,
        },
      });

      toast.success("Usuário atualizado com sucesso!");
      refetch();
    } catch (err: any) {
      errorHandler(err);
    }
    setDialogEditUserIsOpen(false);
    setLoading(false);
  };

  if (loadingQuery || loadingTeams) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Typography
          marginBottom={2}
          variant="h5"
          color={(theme) => theme.palette.secondary.main}
        >
          <strong>Imobiliária: </strong>
          {realEstate?.name}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmitAddUser(onSubmitAddUser)}>
          <Accordion sx={{ margin: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Cadastrar Usuário</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                sx={{ margin: 1 }}
                fullWidth
                label="Nome"
                {...registerAddUser("name")}
                required
              />
              <TextField
                sx={{ margin: 1 }}
                fullWidth
                type="email"
                label="Email"
                {...registerAddUser("email")}
                required
              />
              <CPFInput required register={registerAddUser} name="CPF" />
              <FormControl fullWidth sx={{ margin: 1 }}>
                <InputLabel id="select-roles-label">Permissões</InputLabel>
                <Select
                  multiple
                  labelId="select-roles-label"
                  label="Permissões"
                  required
                  defaultValue={[]}
                  {...registerAddUser("roles")}
                >
                  {Object.values(RoleRealEstate).map((r) => (
                    <MenuItem value={r} key={r}>
                      {r}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }}
              >
                <Button type="submit">Cadastrar</Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" color={(theme) => theme.palette.primary.main}>
          <strong>Equipes</strong>
          <Tooltip
            title="Adicionar Equipe"
            onClick={() => setDialogCreateTeamIsOpen(true)}
          >
            <IconButton color="success" sx={{ marginX: 2 }}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          maxHeight="150px"
          overflow="auto"
        >
          {dataTeams?.teams && dataTeams?.teams.length < 1 && (
            <Typography>Sem equipes</Typography>
          )}
          {dataTeams?.teams &&
            dataTeams?.teams?.map((t) => (
              <Button
                key={`${t.id}-${t.name}`}
                onClick={() => navigate(`/imobiliaria/equipe/${t.id}`)}
              >
                <ListItem
                  key={`${t.name}-${t.id}-${new Date().toString()}`}
                  alignItems="flex-start"
                  sx={{ maxWidth: "300px" }}
                >
                  <ListItemAvatar>
                    <Avatar {...avatarGenerate(t.name)} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={t.name}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Gerente: {t.owner.name}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Button>
            ))}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" color={(theme) => theme.palette.primary.main}>
          <strong>Membros</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color={(theme) => theme.palette.secondary.main}
        >
          <Checkbox
            value={onlyActive}
            onChange={() => setOnlyActive(!onlyActive)}
          />
          <strong>Somente Usuários Ativos</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          hideFooterPagination
          rows={
            transformData(
              data?.realEstate.users.filter((u) =>
                onlyActive ? u.active === true : true
              )
            ) || []
          }
          columns={columns}
          isRowSelectable={() => false}
          onRowClick={(params) => {
            if (params.row.roles.includes("Imobiliaria"))
              return toast.warn(
                "Você não pode editar o usuário da imobiliária"
              );
            setUserSelected(params.row);
            setValue("id", params.row.id);
            setValue("name", params.row.name || "");
            setValue("email", params.row.email || "");
            setValue("CPF", params.row.CPF || "");
            setValue("active", params.row.active === "Sim");
            setValue(
              "roles",
              data?.realEstate.users.find((u) => u.id === params.row?.id)
                ?.roles || []
            );
            setDialogEditUserIsOpen(true);
          }}
        />
      </Grid>
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <form onSubmit={handleEditUserSubmitDialog(onSubmitEditUser)}>
        <Dialog
          open={dialogEditUserIsOpen}
          onClose={() => setDialogEditUserIsOpen(false)}
          maxWidth={"xs"}
          fullWidth
        >
          <DialogTitle>Editar Usuário</DialogTitle>
          <DialogContent dividers>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={getValues("active")}
                  onChange={(e) => {
                    setValue("active", e.target.checked);
                  }}
                />
              }
              label="Ativo"
              sx={{ marginY: 1 }}
            />
            <TextField
              sx={{ marginY: 1 }}
              fullWidth
              label="Nome"
              {...registerEditUserDialog("name")}
              required
            />
            <TextField
              sx={{ marginY: 1 }}
              fullWidth
              type="email"
              label="Email"
              {...registerEditUserDialog("email")}
              required
            />
            <CPFInput required register={registerEditUserDialog} name="CPF" />
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <InputLabel id="select-roles-label">Permissões</InputLabel>
              <Select
                multiple
                labelId="select-roles-label"
                label="Permissões"
                required
                defaultValue={
                  data?.realEstate.users.find((u) => u.id === userSelected?.id)
                    ?.roles || []
                }
                {...registerEditUserDialog("roles")}
              >
                {Object.values(RoleRealEstate).map((r) => (
                  <MenuItem value={r} key={r}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogEditUserIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                buttonSubmitRef.current && buttonSubmitRef?.current?.click()
              }
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <button type="submit" hidden ref={buttonSubmitRef} />
      </form>

      <form onSubmit={handleSubmitCreateTeam(onSubmitCreateTeam)}>
        <Dialog
          open={dialogCreateTeamIsOpen}
          onClose={() => setDialogCreateTeamIsOpen(false)}
          maxWidth={"xs"}
          fullWidth
        >
          <DialogTitle>Criar Equipe</DialogTitle>
          <DialogContent dividers>
            <TextField
              sx={{ marginY: 1 }}
              fullWidth
              label="Nome"
              {...registerCreateTeam("name")}
              required
            />
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <InputLabel id="select-roles-label">Gerente</InputLabel>
              <Select
                labelId="select-roles-label"
                label="Gerente"
                required
                {...registerCreateTeam("ownerId")}
              >
                {data?.realEstate.users
                  .filter((u) => u.roles?.includes(Role.GERENTE))
                  .map((u) => (
                    <MenuItem value={u.id} key={u.id}>
                      {u.name} - {u.email}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogCreateTeamIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                buttonSubmitCreateTeamRef.current &&
                buttonSubmitCreateTeamRef?.current?.click()
              }
            >
              Criar
            </Button>
          </DialogActions>
        </Dialog>
        <button type="submit" hidden ref={buttonSubmitCreateTeamRef} />
      </form>
    </Grid>
  );
};

export default RealEstate;
