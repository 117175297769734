import * as yup from 'yup';

import { Status } from '../../graphql/entities/pac/status.enum';
import { Type } from '../../graphql/entities/pac/type.enum';

export default yup.object({
  name: yup
    .string()
    .trim()
    .optional()
    .min(3, 'O nome do cliente deve ter pelo menos 3 caracteres'),
  cpf: yup.string().trim().optional().min(14, 'CPF inválido'),
  type: yup.mixed().oneOf(Object.values(Type)).optional(),
  status: yup.mixed().oneOf(Object.values(Status)).optional(),
  userId: yup.string().trim().uuid().optional(),
  teamId: yup.number().integer().optional(),
  createdAt: yup
    .object({
      begin: yup
        .date()
        .typeError(
          'Você deve escolher uma data de início para o campo Data de Criação.'
        ),
      end: yup
        .date()
        .typeError(
          'Você deve escolher uma data final para o campo Data de Criação.'
        ),
    })
    .optional(),
  updatedAt: yup
    .object({
      begin: yup
        .date()
        .typeError(
          'Você deve escolher uma data de início para o campo Última Atualização.'
        ),
      end: yup
        .date()
        .typeError(
          'Você deve escolher uma data final para o campo Última Atualização.'
        ),
    })
    .optional(),
});
