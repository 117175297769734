import { Info, LabelImportant as LabelIcon } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  ListItem,
  ListItemButton,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { format, formatDistance, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";
import React from "react";
import { useNavigate } from "react-router-dom";

// THEME
import { themeStyledComponent } from "../../styles/theme";

// Entities
import { Pac } from "../../graphql/entities/pac";

// import { Container } from './styles';

interface Props {
  pacs: Pac[];
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
}

const PacsCard: React.FC<Props> = ({ pacs, totalPages, page, setPage }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      {pacs.length > 0 && (
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            marginY: 2,
            justifyContent: "center",
          }}
        >
          <Pagination
            page={page}
            onChange={(e, page) => setPage(page)}
            count={totalPages}
            color="primary"
          />
        </Container>
      )}

      <Stack direction="row" flexWrap="wrap" margin={1} justifyContent="center">
        {pacs.length <= 0 && (
          <Typography variant="h5" sx={{ margin: 2 }} textAlign="center">
            <strong>
              NENHUMA PAC ENCONTRADA COM OS CRITÉRIOS SELECIONADOS!
            </strong>
          </Typography>
        )}
        {pacs.map((p) => (
          <ListItem
            sx={{
              width: "320px",
              display: "flex",
              padding: 0,
            }}
            key={`${new Date().getTime()}-${p.id}`}
          >
            <ListItemButton
              onClick={() => navigate(`/pac/${p.id}`)}
              sx={{ display: "flex" }}
            >
              <Card
                sx={{
                  width: "100%",
                  display: "flex",
                  background: theme.palette.primary.main,
                  flexGrow: 1,
                }}
              >
                <Box sx={{ display: "flex", flexGrow: 1 }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      color: theme.palette.common.white,
                    }}
                  >
                    <header
                      style={{
                        borderBottom: `1px solid ${theme.palette.secondary.main}`,
                        marginBottom: 10,
                      }}
                    >
                      <Typography variant="h5">
                        PAC <strong>#{p.id}</strong>
                      </Typography>
                    </header>
                    <Box>
                      <Typography width="260px" sx={{ wordWrap: "break-word" }}>
                        <strong>Cliente: </strong>
                        {p.nameClient}
                      </Typography>
                      <Typography width="260px" sx={{ wordWrap: "break-word" }}>
                        <strong>CPF: </strong>
                        {p.CPFClient}
                      </Typography>
                    </Box>
                    <Box sx={{ opacity: 0.8 }}>
                      {p.nameConjuge && (
                        <Typography
                          width="260px"
                          sx={{ wordWrap: "break-word" }}
                        >
                          <strong>Conjugê: </strong>
                          {p.nameConjuge}
                        </Typography>
                      )}
                      {p.CPFConjuge && (
                        <Typography
                          width="260px"
                          sx={{ wordWrap: "break-word" }}
                        >
                          <strong>CPF Conj: </strong>
                          {p.CPFConjuge}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        borderTop: `1px solid ${themeStyledComponent.third}`,
                        marginTop: 1,
                        paddingTop: 1,
                      }}
                    >
                      {p.user && p?.user.name && (
                        <Typography
                          width="260px"
                          sx={{ wordWrap: "break-word" }}
                        >
                          <strong>Responsável: </strong>
                          {p?.user.name}
                        </Typography>
                      )}
                      {p.user &&
                        p?.user.realEstate &&
                        p?.user?.realEstate.name && (
                          <Typography
                            width="260px"
                            sx={{ wordWrap: "break-word" }}
                          >
                            <strong>Imobiliária: </strong>
                            {p?.user?.realEstate.name}
                          </Typography>
                        )}
                      {p.user &&
                        p?.user?.TeamUsers &&
                        p?.user?.TeamUsers.length > 0 &&
                        p?.user?.TeamUsers[0].team && (
                          <Typography
                            width="260px"
                            sx={{ wordWrap: "break-word" }}
                          >
                            <strong>Equipe: </strong>
                            {p?.user?.TeamUsers[0].team?.name}
                          </Typography>
                        )}
                      {p.user &&
                        p?.user?.TeamUsers &&
                        p?.user?.TeamUsers.length > 0 && (
                          <Typography
                            width="260px"
                            sx={{ wordWrap: "break-word" }}
                          >
                            <strong>Gerente: </strong>
                            {p?.user?.TeamUsers.map(
                              (t) => t.team.owner?.name
                            ).join(" / ")}
                          </Typography>
                        )}
                      {p?.user?.constructionCompany?.name && (
                        <Typography
                          width="260px"
                          sx={{ wordWrap: "break-word" }}
                        >
                          <strong>Construtora do Resp.: </strong>
                          {p?.user?.constructionCompany?.name}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        borderTop: `1px solid ${themeStyledComponent.third}`,
                        marginTop: 1,
                        paddingTop: 1,
                      }}
                    >
                      {p?.operator?.name && (
                        <Typography
                          width="260px"
                          sx={{ wordWrap: "break-word" }}
                        >
                          <strong>Operador Resp.: </strong>
                          {p?.operator?.name}
                        </Typography>
                      )}
                      {p?.constructionCompany?.name && (
                        <Typography
                          width="260px"
                          sx={{ wordWrap: "break-word" }}
                        >
                          <strong>PAC Vinc. A Const.: </strong>
                          {p?.constructionCompany?.name}
                        </Typography>
                      )}
                      <Typography width="260px" sx={{ wordWrap: "break-word" }}>
                        <strong>Produto: </strong>
                        {p.product}
                      </Typography>
                      <Typography width="260px" sx={{ wordWrap: "break-word" }}>
                        <strong>Criado em: </strong>
                        {format(parseISO(String(p.createdAt) || ""), "PPPPp", {
                          locale: pt,
                        })}
                      </Typography>
                      <Typography width="260px" sx={{ wordWrap: "break-word" }}>
                        <strong>Última atualização: </strong>
                        {formatDistance(
                          parseISO(String(p.updatedAt) || ""),
                          new Date(),
                          {
                            locale: pt,
                            addSuffix: true,
                          }
                        )}
                      </Typography>
                      <Chip
                        sx={{ width: "260px", marginTop: 1 }}
                        label={p.type}
                        icon={<LabelIcon />}
                        color={"primary"}
                      />
                      <Tooltip title={p.status || ""} arrow>
                        <Chip
                          sx={{ width: "260px", marginTop: 1 }}
                          label={p.status}
                          icon={<Info />}
                          color="secondary"
                        />
                      </Tooltip>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </ListItemButton>
          </ListItem>
        ))}
      </Stack>
      {pacs.length > 0 && (
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            marginY: 2,
            justifyContent: "center",
          }}
        >
          <Pagination
            page={page}
            onChange={(e, page) => setPage(page)}
            count={totalPages}
            color="primary"
          />
        </Container>
      )}
    </>
  );
};

export default PacsCard;
