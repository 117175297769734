import { gql } from "@apollo/client";

export interface Response {
  removeTeam: any;
}

export interface Variables {
  id: number;
}

export default gql`
  mutation removeTeam($id: Int!) {
    removeTeam(id: $id) {
      name
    }
  }
`;
