import React, { useEffect, useRef, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  AddCircle as AddIcon,
  DeleteForever as DeleteIcon,
  Edit as EditIcon,
  Logout as ExitIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppSelector } from "../../store/hooks";

// Queries
import getTeam, { Response, Variables } from "../../graphql/findOneTeam";
import getUsersRealEstate, {
  Response as RUsersRealEstate,
  Variables as VUsersRealEstate,
} from "../../graphql/getUsersRealEstate";

// Mutation
import insertUserTeamMutation, {
  Response as RInsertUserTeam,
  Variables as VInsertUserTeam,
} from "../../graphql/insertUserTeamMutation";

import removeUserTeamMutation, {
  Response as RRemoveUserTeam,
  Variables as VRemoveUserTeam,
} from "../../graphql/removeUserTeamMutation";

import removeTeamMutation, {
  Response as RRemoveTeam,
  Variables as VRemoveTeam,
} from "../../graphql/removeTeamMutation";

import updateTeamMutation, {
  Response as RUpdateTeam,
  Variables as VUpdateTeam,
} from "../../graphql/updateTeamMutation";

// Types
import { Role } from "../../graphql/entities/role/enum";

// Utils
import avatarGenerate from "../../utils/avatarGenerate";
import errorHandler from "../../utils/errorHandler";
import permission from "../../utils/verifyPermission";

const Team: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const roles = useAppSelector((state) => state.auth.user?.roles || []);
  const realEstate = useAppSelector((state) => state.auth.user?.realEstate);

  // Queries

  const {
    data,
    loading: loadingTeam,
    error,
    refetch,
  } = useQuery<Response, Variables>(getTeam, {
    variables: {
      id: Number(id),
    },
  });

  const { data: dataRealEstate, loading: loadingUsers } = useQuery<
    RUsersRealEstate,
    VUsersRealEstate
  >(getUsersRealEstate, {
    variables: { id: Number(realEstate?.id) || 0 },
  });

  // End

  const [loading, setLoading] = useState(false);
  // Dialog Insert Member
  const [isDialogAddMemberOpen, setIsDialogAddMemberOpen] = useState(false);
  const [usersSelected, setUsersSelected] = useState<string[]>([]);

  const [runInsertUserTeamMutation] = useMutation<
    RInsertUserTeam,
    VInsertUserTeam
  >(insertUserTeamMutation);

  const handleSubmitAddMember = async () => {
    setIsDialogAddMemberOpen(false);
    setUsersSelected([]);
    setLoading(true);

    try {
      await runInsertUserTeamMutation({
        variables: {
          insertTeamUserInput: {
            teamId: Number(id),
            users: usersSelected,
          },
        },
      });

      toast.success("Membros adicionados com sucesso!");
      refetch();
    } catch (err: any) {
      errorHandler(err);
    }

    setLoading(false);
  };

  // End

  // Remove Member Team

  const [runRemoveMember] = useMutation<RRemoveUserTeam, VRemoveUserTeam>(
    removeUserTeamMutation
  );

  const handleRemoveMember = async (idUser: string) => {
    if (
      !window.confirm(
        "Você tem certeza que deseja remover o membro da equipe ?"
      )
    )
      return;

    setLoading(true);

    try {
      await runRemoveMember({
        variables: {
          id: idUser,
          idTeam: Number(id),
        },
      });

      toast.success("Membro removido com sucesso!");
      refetch();
    } catch (err: any) {
      errorHandler(err);
    }

    setLoading(false);
  };

  // End

  // Delete Team
  const [runRemoveTeam] = useMutation<RRemoveTeam, VRemoveTeam>(
    removeTeamMutation
  );

  const handleRemoveTeam = async () => {
    if (!window.confirm("Você tem certeza que deseja deletar a equipe ?"))
      return;

    setLoading(true);

    try {
      await runRemoveTeam({
        variables: {
          id: Number(id),
        },
      });

      toast.success("Equipe deletada com sucesso!");
      navigate("/");
    } catch (err: any) {
      errorHandler(err);
    }

    setLoading(false);
  };
  // End

  // Edit Team
  const buttonSubmitEditTeamRef = useRef<HTMLButtonElement>(null);
  const {
    register: registerEditTeam,
    handleSubmit: handleSubmitEditTeam,
    setValue: setEditTeamValue,
  } = useForm();

  const [runUpdateTeam] = useMutation<RUpdateTeam, VUpdateTeam>(
    updateTeamMutation
  );
  const [isDialogEditTeamOpen, setIsDialogEditTeamOpen] = useState(false);

  const onSubmitEditTeam = async (data: any) => {
    const typedData = data as VUpdateTeam["updateTeamInput"];

    if (typedData.name.length < 3)
      return toast.warn("O nome da equipe deve conter no mínimo 3 caracteres.");

    if (typedData.ownerId.length < 3)
      return toast.warn("Selecione um gerente para a equipe.");

    setIsDialogEditTeamOpen(false);
    setLoading(true);

    try {
      await runUpdateTeam({
        variables: {
          updateTeamInput: {
            id: Number(id),
            name: typedData.name,
            ownerId: typedData.ownerId,
          },
        },
      });

      toast.success("Equipe atualizada com sucesso!");
      refetch();
    } catch (err: any) {
      errorHandler(err);
    }

    setLoading(false);
  };

  // End

  useEffect(() => {
    setEditTeamValue("ownerId", data?.team.owner.id);
    setEditTeamValue("name", data?.team.name);
  }, [data?.team, setEditTeamValue]);

  if (error) {
    toast.error("Equipe não encontrada", { toastId: 297 });
    return <Navigate to="/" />;
  }

  return (
    <Grid container spacing={2} padding={2}>
      <Grid
        item
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        xs={12}
        gap={4}
      >
        <Avatar
          {...avatarGenerate(data?.team.name || "")}
          style={{ width: 80, height: 80 }}
        />
        <div>
          <Typography
            component="p"
            variant="h4"
            color="text.primary"
            textAlign="center"
          >
            {data?.team.name}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            color="text.primary"
            textAlign="center"
          >
            Gerente: {data?.team.owner.name}
          </Typography>
        </div>
        <div>
          {permission([Role.IMOBILIARIA], roles) && (
            <>
              <Tooltip title="Adicionar Membro">
                <IconButton
                  color="success"
                  size="medium"
                  onClick={() => setIsDialogAddMemberOpen(true)}
                >
                  <AddIcon fontSize="medium" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Editar Equipe">
                <IconButton
                  color="warning"
                  size="medium"
                  onClick={() => setIsDialogEditTeamOpen(true)}
                >
                  <EditIcon fontSize="medium" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Deletar Equipe" onClick={handleRemoveTeam}>
                <IconButton color="error" size="medium">
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" variant="h4">
          Membros
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {data?.team.members && data?.team.members.length < 1 && (
            <Typography textAlign="center" variant="body1">
              A equipe não possui membros!
            </Typography>
          )}
          {data?.team.members.map((m) => (
            <ListItemButton sx={{ maxWidth: 300, width: 300 }}>
              <ListItem
                secondaryAction={
                  permission([Role.IMOBILIARIA], roles) && (
                    <>
                      <Tooltip title="Remover membro">
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => handleRemoveMember(m?.id || "")}
                        >
                          <ExitIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar {...avatarGenerate(m.name || "")} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: "block",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "130px",
                        }}
                        component="p"
                        variant="body2"
                        color="text.primary"
                        fontWeight="500"
                      >
                        {m.name}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={m.roles
                    ?.map(
                      (r) => r.name.charAt(0).toUpperCase() + r.name.slice(1)
                    )
                    .join(",")}
                />
              </ListItem>
            </ListItemButton>
          ))}
        </List>
      </Grid>

      <Dialog maxWidth={"xs"} fullWidth open={isDialogAddMemberOpen}>
        <DialogTitle>Escolher Membros</DialogTitle>
        <List>
          {dataRealEstate?.realEstate.users &&
            dataRealEstate?.realEstate.users.filter(
              (u) =>
                !u.roles?.some((r) =>
                  [Role.COORDENADOR, Role.GERENTE, Role.IMOBILIARIA].includes(
                    r as Role
                  )
                )
            ).length < 1 && (
              <ListItemText sx={{ textAlign: "center" }}>
                Não há usuários disponíveis
              </ListItemText>
            )}
          {dataRealEstate?.realEstate.users
            .filter(
              (u) =>
                !u.roles?.some((r) =>
                  [Role.COORDENADOR, Role.IMOBILIARIA].includes(r as Role)
                )
            )
            .filter((u) => u.active)
            .map((u) => (
              <ListItem
                sx={{ width: "90%", marginX: 2 }}
                disableGutters
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={(e, c) =>
                      c
                        ? setUsersSelected([...usersSelected, u?.id || ""])
                        : setUsersSelected(
                            usersSelected.filter((i) => i !== u.id)
                          )
                    }
                  />
                }
              >
                <ListItemAvatar>
                  <Avatar {...avatarGenerate(u.name || "")} />
                </ListItemAvatar>
                <ListItemText primary={u.name} secondary={u.email} />
              </ListItem>
            ))}
        </List>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDialogAddMemberOpen(false);
              setUsersSelected([]);
            }}
          >
            SAIR
          </Button>
          {usersSelected.length > 0 && (
            <Button onClick={handleSubmitAddMember}>CONFIRMAR</Button>
          )}
        </DialogActions>
      </Dialog>

      <form onSubmit={handleSubmitEditTeam(onSubmitEditTeam)}>
        <Dialog
          open={isDialogEditTeamOpen}
          onClose={() => setIsDialogEditTeamOpen(false)}
          maxWidth={"xs"}
          fullWidth
        >
          <DialogTitle>Editar Equipe</DialogTitle>
          <DialogContent dividers>
            <TextField
              sx={{ marginY: 1 }}
              fullWidth
              label="Nome"
              {...registerEditTeam("name")}
              defaultValue={data?.team.name}
              required
            />
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <InputLabel id="select-roles-label">Gerente</InputLabel>
              <Select
                labelId="select-roles-label"
                label="Gerente"
                required
                {...registerEditTeam("ownerId")}
                defaultValue={data?.team.owner.id || ""}
              >
                {dataRealEstate?.realEstate.users
                  .filter((u) => u.roles?.includes(Role.GERENTE))
                  .map((u) => (
                    <MenuItem value={u.id} key={u.id}>
                      {u.name} - {u.email}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogEditTeamOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                buttonSubmitEditTeamRef.current &&
                buttonSubmitEditTeamRef?.current?.click()
              }
            >
              Criar
            </Button>
          </DialogActions>
        </Dialog>
        <button type="submit" hidden ref={buttonSubmitEditTeamRef} />
      </form>

      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading || loadingTeam || loadingUsers}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </Grid>
  );
};

export default Team;
