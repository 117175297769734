import { gql } from '@apollo/client';

export interface Response {
  createRealEstate: {
    id: string;
  };
}

export interface Variables {
  input: {
    name: string;
    email: string;
    CNPJ: string;
  };
}

export default gql`
  mutation ($input: CreateRealEstateInput!) {
    createRealEstate(createRealEstateInput: $input) {
      id
    }
  }
`;
