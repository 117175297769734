import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Email, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import logo from '../../assets/logo2.png';
import MoneImg from '../../assets/mone/3.png';
import LoginMutation, { Response } from '../../graphql/loginMutation';
import {
  setLogged,
  setToken,
  setUser,
} from '../../store/modules/auth/authSlice';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../store';
import { Container, FormContainer, Logo, Mone } from './styles';
import validate from './validate';

const Login: React.FC = () => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  });
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();
  const logged = useSelector((state: RootState) => state.auth.logged);

  const [login, { loading }] = useMutation<Response>(LoginMutation);

  async function onSubmit(data: any) {
    const { email, password: pass } = data as {
      email: string;
      password: string;
    };

    if (Object.keys(errors).length > 0) return;

    try {
      const { data: dataMutation } = await login({
        variables: { email, pass },
      });
      if (dataMutation?.login) {
        dispatch(setToken(dataMutation.login.token));
        dispatch(setLogged(true));
        dispatch(setUser(dataMutation.login.user));
        navigate(searchParams.get('backUrl') || '/');
      }
    } catch (err: any) {
      toast.error(err?.message);
    }
  }

  useEffect(() => {
    if (logged) navigate(searchParams.get('backUrl') || '/');
  }, [logged, navigate, searchParams]);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Logo src={logo} alt="Unifica Cred" />

      <FormContainer maxWidth="xs">
        {loading && (
          <CircularProgress
            sx={{
              alignSelf: 'center',
              margin: 'auto auto',
            }}
          />
        )}
        {!loading && (
          <>
            <FormControl margin="normal">
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                autoComplete="username"
                type="email"
                label="Email"
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
                {...register('email')}
                required
                error={String(errors['email']?.message || '').length > 0}
              />
            </FormControl>
            <FormControl margin="normal">
              <InputLabel htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                autoComplete="current-password"
                required
                {...register('password')}
                type={showPass ? 'text' : 'password'}
                label="Senha"
                error={String(errors['password']?.message || '').length > 0}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {showPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {String(errors['password']?.message || '')}
              </FormHelperText>
            </FormControl>

            <Button type="submit">Entrar</Button>
            <Link
              to="/recuperar"
              style={{
                textAlign: 'center',
                textDecoration: 'none',
                color: theme.palette.grey.A700,
                opacity: 1,
                margin: '5px',
              }}
            >
              Recuperar/Mudar Senha
            </Link>
          </>
        )}
      </FormContainer>

      <Mone src={MoneImg} alt="Mone" />
    </Container>
  );
};

export default Login;
