const initialDocs = [
  'RG E CPF / CNH*',
  'CERTIDÃO DE NASCIMENTO/CASAMENTO',
  'COMPROVANTE DE RENDA',
  'COMPROVANTE DE RESIDÊNCIA',
  'CARTEIRA DE TRABALHO',
  'DECLA. DE IMPOSTO DE RENDA',
  'CÔNJUGE',
  'DEPENDENTE',
];

export default initialDocs;
