import { gql } from '@apollo/client';
import { RoleConstructionCompany } from './entities/role/enum';

export interface Response {
  updateUserConstructionCompany: {
    id: string;
  };
}

export interface Variables {
  input: {
    id: string;
    name?: string;
    CPF?: string;
    roles?: RoleConstructionCompany[];
    email?: string;
    active?: boolean;
  };
}

export default gql`
  mutation ($input: UpdateUserConstructionCompany!) {
    updateUserConstructionCompany(updateUserConstructionCompanyInput: $input) {
      id
    }
  }
`;
