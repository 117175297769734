import { FastForward } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';
import React from 'react';

// import { Container } from './styles';

interface Props {
  title: string;
  quantity: string | number;
  onClick?: () => any;
  backgroundColor: string;
}

const CardStatus: React.FC<Props> = ({
  title,
  quantity,
  onClick,
  backgroundColor,
}) => {
  return (
    <Container
      maxWidth="xs"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        margin: 0,
        marginBottom: { xs: '90px', sm: '120px' },
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box sx={{ position: 'absolute', zIndex: -1 }}>
        <FastForward
          sx={{
            fontSize: { xs: '310px', sm: '400px' },
            color: 'black',
            opacity: 0.05,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: { xs: '140px' },
          width: { xs: '200px', sm: '250px' },
          background: backgroundColor,
          borderRadius: '10px',
        }}
      >
        <Typography
          padding={1}
          color="white"
          fontWeight="bold"
          textAlign="center"
          sx={{
            background: theme => theme.palette.primary.main,
            borderRadius: '9px 9px 0px 0px',
            boxShadow: '0px 1px 1px #ccc',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.6,
            flex: 1,
          }}
        >
          <Typography fontWeight="bold" variant="h2">
            {quantity}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default CardStatus;
