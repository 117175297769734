import React, { useState } from 'react';

import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FilePondFile } from 'filepond';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import axios from 'axios';
import apiBaseURL from '../../services/apiBaseURL';

// Validation
import Validation from './validate';

// Utils
import verifyCPF from '../../utils/verifyCPF';

// Types
import initialDocs from '../../types/initialDocs';

// Type
import { Type } from '../../graphql/entities/pac/type.enum';

// Components
import CEPInput from '../../components/CEPInput';
import CPFInput from '../../components/CPFInput';
import FileUploadCreate from '../../components/FileUploadCreate';
import PhoneInput from '../../components/PhoneInput';

import { useAppSelector } from '../../store/hooks';
import { Container } from './styles';

const CreatePac: React.FC = () => {
  const token = useAppSelector(state => state.auth.token) || '';
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm();
  const [files, setFiles] = useState<FilePondFile[]>([]);

  async function onSubmit(data: any) {
    if (files.length < 1)
      return toast.warn(
        'É necessário pelo menos um documento de identificação!'
      );

    ['nameConjuge', 'nameClient', 'product', 'address'].forEach(key => {
      data[key] = String(data[key]).toUpperCase();
    });

    if (data.nameConjuge.length === 0) delete data['nameConjuge'];
    if (data.CPFConjuge.length === 0) delete data['CPFConjuge'];
    if (!verifyCPF(data.CPFClient))
      return toast.error('CPF do Cliente Inválido!');
    if (data.CPFConjuge && !verifyCPF(data.CPFConjuge))
      return toast.error('CPF do Cônjuge Inválido!');

    setLoading(true);
    try {
      Validation.validateSync(data);

      const formData = new FormData();

      const keys = Object.keys(data);

      for (let key of keys) {
        formData.append(key, data[key]);
      }

      for (let file of files) {
        formData.append('file', file.file);
      }

      const response = await axios({
        method: 'post',
        url: `${apiBaseURL}/pacs`,
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: formData,
      });

      const pacId = response.data.id;
      if (!pacId)
        return toast.error('Ocorreu um erro ao criar ao tentar criar a PAC!');
      toast.success('Pac criada com sucesso!');
      navigate(`/pac/${pacId}`);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/pacs">
            Pacs
          </Link>
          <Typography color="text.primary">Criar</Typography>
        </Breadcrumbs>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12} sm={12} md={5}>
            <TextField
              sx={{ margin: 1 }}
              label="Nome do Cliente"
              fullWidth
              required
              {...register('nameClient')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CPFInput required register={register} name="CPFClient" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              sx={{ margin: 1 }}
              label="Email"
              type="email"
              required
              fullWidth
              {...register('emailClient')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PhoneInput register={register} name="phoneClient" required />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <TextField
              sx={{ margin: 1 }}
              label="Nome do Cônjuge"
              fullWidth
              {...register('nameConjuge')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CPFInput
              register={register}
              name="CPFConjuge"
              label="CPF do Cônjuge"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              sx={{ margin: 1 }}
              label="Email do Conjugê"
              type="email"
              fullWidth
              {...register('emailConj')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PhoneInput
              register={register}
              name="phoneConj"
              label="Telefone Conjugê"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextField
              sx={{ margin: 1 }}
              label="Endereço de residência"
              fullWidth
              {...register('address')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CEPInput register={register} name="CEP" />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth sx={{ margin: 1 }}>
              <InputLabel id="type-label">Tipo</InputLabel>
              <Select
                labelId="type-label"
                label="Tipo"
                fullWidth
                required
                {...register('type')}
              >
                {Object.values(Type).map(t => (
                  <MenuItem key={`${new Date().getTime()}-${t}`} value={t}>
                    {t}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <TextField
              sx={{ margin: 1 }}
              label="Produto"
              required
              fullWidth
              {...register('product')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={'bold'}>
              {initialDocs.find(v => v.search('RG') !== -1)}
            </Typography>
            <FileUploadCreate files={files} setFiles={setFiles} />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="success">
              Criar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Backdrop
        sx={{
          color: theme => theme.palette.primary.main,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </Container>
  );
};

export default CreatePac;
