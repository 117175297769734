import { useMutation, useQuery } from "@apollo/client";
import {
  Add as AddIcon,
  AssignmentInd,
  Clear as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Folder as FolderIcon,
  History as HistoryIcon,
  Info as InfoIcon,
  Lock as LockIcon,
  Done as SaveIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppSelector } from "../../store/hooks";

import { Container } from "./styles";

// Query
import findPac, { Response } from "../../graphql/findPac";

// Mutation
import bindPacOperatorMutation, {
  Response as ResponseBindPacOperator,
  Variables as VariablesBindPacOperator,
} from "../../graphql/bindPacOperatorMutation";
import createPacHistoryMutation, {
  Response as ResponseCreatePacHistory,
  Variables as VariablesCreatePacHistory,
} from "../../graphql/createPacHistoryMutation";
import deletePacMutation from "../../graphql/deletePacMutation";
import unbindPacConstructionCompanyMutation, {
  Response as ResponseUnbindPacConstructionCompany,
  Variables as VariablesUnbindPacConstructionCompany,
} from "../../graphql/unbindPacConstructionCompanyMutation";
import unbindPacOperatorMutation, {
  Response as ResponseUnbindPacOperator,
  Variables as VariablesUnbindPacOperator,
} from "../../graphql/unbindPacOperatorMutation";
import updatePacMutation, {
  Response as ResponseUpdatePac,
  Variables,
} from "../../graphql/updatePacMutation";

// Enums
import { Status } from "../../graphql/entities/pac/status.enum";
import { Type } from "../../graphql/entities/pac/type.enum";
import { Role } from "../../graphql/entities/role/enum";

// Type
import { Document } from "../../graphql/entities/document";
import { History as IHistory } from "../../graphql/entities/history";
import { Pac as IPac } from "../../graphql/entities/pac";

// Component
import CEPInput from "../../components/CEPInput";
import CPFInput from "../../components/CPFInput";
import DialogSelectConstructionCompany from "../../components/DialogSelectConstructionCompany";
import DocumentsTable from "../../components/DocumentsTable";
import FileUpload from "../../components/FileUpload";
import History from "../../components/History";
import PhoneInput from "../../components/PhoneInput";

// Initial Docs
import initialDocs from "../../types/initialDocs";

// Utils
import errorHandler from "../../utils/errorHandler";
import verifyCPF from "../../utils/verifyCPF";
import permission from "../../utils/verifyPermission";

// Validate
import Validation from "./validate";

const Pac: React.FC = () => {
  const [runDeletePac] = useMutation(deletePacMutation);
  const [runUpdatePac] = useMutation<ResponseUpdatePac, Variables>(
    updatePacMutation
  );
  const [runCreatePacHistory] = useMutation<
    ResponseCreatePacHistory,
    VariablesCreatePacHistory
  >(createPacHistoryMutation);
  const [runBindPacOperator] = useMutation<
    ResponseBindPacOperator,
    VariablesBindPacOperator
  >(bindPacOperatorMutation);
  const [runUnbindPacOperator] = useMutation<
    ResponseUnbindPacOperator,
    VariablesUnbindPacOperator
  >(unbindPacOperatorMutation);
  const [runUnbindPacConstructionCompany] = useMutation<
    ResponseUnbindPacConstructionCompany,
    VariablesUnbindPacConstructionCompany
  >(unbindPacConstructionCompanyMutation);

  const [globalLoading, setGlobalLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: DataPac,
    loading,
    error,
  } = useQuery<Response>(findPac, {
    variables: { id: Number(id) },
  });

  const roles = useAppSelector((state) => state.auth.user?.roles || []);
  const [tab, setTab] = useState(0);
  const [data, setData] = useState<IPac>({});
  const [edit, setEdit] = useState(false);
  const [histories, setHistories] = useState<IHistory[]>([]);

  const [editInternal, setEditInternal] = useState(false);

  const [IDsInternalInfos, setIDsInternalInfos] = useState<string[]>([]);
  const [IDsInfos, setIDsInfos] = useState<string[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);

  const initialType = useMemo(() => DataPac?.pac.type || "", [DataPac]);
  const initialStatus = useMemo(() => DataPac?.pac.status || "", [DataPac]);

  // Dialogs
  const [dialogOpen, setIsDialogOpen] = useState(false);
  const [dialogInfosOpen, setIsDialogInfosOpen] = useState(false);
  const [dialogDocOpen, setIsDialogDocOpen] = useState(false);
  const [dialogNewHistoryOpen, setIsDialogNewHistoryOpen] = useState(false);
  const [dialogConstructionCompanyOpen, setIsDialogConstructionCompanyOpen] =
    useState(false);

  const [IDsDocs, setIDsDocs] = useState<string[]>(initialDocs);

  const { register, handleSubmit, setValue, unregister, resetField } =
    useForm();

  const { register: registerNewHistory, handleSubmit: handleSubmitNewHistory } =
    useForm();

  useEffect(() => {
    if (DataPac?.pac) {
      setData(
        {
          ...DataPac?.pac,
          internalInfos: DataPac?.pac?.internalInfos
            ? JSON.parse(DataPac?.pac?.internalInfos)
            : {},
          infos: DataPac?.pac?.infos ? JSON.parse(DataPac?.pac?.infos) : {},
          documents: undefined,
        } || {}
      );
      for (let key of Object.keys(DataPac.pac)) {
        //@ts-ignore
        if (DataPac.pac[key]) {
          //@ts-ignore
          if (key === "internalInfos") {
            //@ts-ignore
            setValue(key, JSON.parse(DataPac.pac[key]) || "");
            continue;
          }

          if (key === "infos") {
            //@ts-ignore
            setValue(key, JSON.parse(DataPac.pac[key]) || "");
            continue;
          }

          if (key === "histories") setHistories(DataPac.pac[key] || []);

          if (key === "documents") {
            const docsName = DataPac.pac[key]?.map((d) => d.name) as string[];
            if (docsName?.length > 0)
              //@ts-ignore
              setIDsDocs([...new Set([...initialDocs, ...docsName])]);
            //@ts-ignore
            if (DataPac.pac[key]?.length > 0) setDocuments(DataPac.pac[key]);
            continue;
          }
          //@ts-ignore
          setValue(key, DataPac.pac[key]);
        }
      }
    }
  }, [DataPac, setValue]);

  const inputDialog = useRef<HTMLInputElement>(null);
  const inputInfosDialog = useRef<HTMLInputElement>(null);
  const inputDialogDoc = useRef<HTMLInputElement>(null);

  const handleDelete = async () => {
    if (!window.confirm("Realmente deseja deletar a pac ?")) return;
    setGlobalLoading(true);
    try {
      await runDeletePac({
        variables: {
          id: Number(id),
        },
      });
      toast.success("Pac deletada com sucesso!");
      navigate("/pacs");
    } catch (err: any) {
      toast.error(err?.message);
    }
    setGlobalLoading(false);
  };

  const handleDialogSubmit = () => {
    if (!inputDialog.current) return;
    const value = inputDialog?.current.value.toUpperCase() || "";
    if (value.length < 3) return;
    if (
      IDsInternalInfos.includes(value) ||
      Object.keys(data?.internalInfos || {}).includes(value)
    )
      return toast.error("Identificador já existe!");
    setIDsInternalInfos([...IDsInternalInfos, value]);
    setIsDialogOpen(false);
    inputDialog.current.value = "";
  };

  const handleDialogInfosSubmit = () => {
    if (!inputInfosDialog.current) return;
    const value = inputInfosDialog?.current.value.toUpperCase() || "";
    if (value.length < 3) return;
    if (
      IDsInfos.includes(value) ||
      Object.keys(data?.infos || {}).includes(value)
    )
      return toast.error("Identificador já existe!");
    setIDsInfos([...IDsInfos, value]);
    setIsDialogInfosOpen(false);
    inputInfosDialog.current.value = "";
  };

  const handleDialogDocSubmit = () => {
    if (!inputDialogDoc.current) return;
    const value = inputDialogDoc?.current.value.toUpperCase() || "";
    if (value.length < 3) return;
    if (value.toUpperCase() === "IMOBZI")
      return toast.error("Esse identificador não é permitido.");
    if (IDsDocs.includes(value)) return toast.error("Identificador já existe!");
    setIDsDocs([...IDsDocs, value]);
    setIsDialogDocOpen(false);
    inputDialogDoc.current.value = "";
  };

  const deleteInternalInfo = (key: string) => {
    const keys = Object.keys(data?.internalInfos || []);
    let internalInfos = {};
    for (const k of keys) {
      if (k !== key) {
        if (data && data?.internalInfos && data?.internalInfos[`${k}`])
          internalInfos = {
            ...internalInfos,
            [`${k}`]: data?.internalInfos[`${k}`],
          };
      }
    }
    setData({ ...data, internalInfos });
    resetField(`internalInfos.${key}`);
    unregister(`internalInfos.${key}`);
  };

  const deleteInfo = (key: string) => {
    const keys = Object.keys(data?.infos || []);
    let infos = {};
    for (const k of keys) {
      if (k !== key) {
        if (data && data?.infos && data?.infos[`${k}`])
          infos = {
            ...infos,
            [`${k}`]: data?.infos[`${k}`],
          };
      }
    }
    setData({ ...data, infos });
    resetField(`infos.${key}`);
    unregister(`infos.${key}`);
  };

  async function onSubmitBindPacOperator(id: number) {
    setGlobalLoading(true);
    try {
      await runBindPacOperator({
        variables: {
          input: {
            id,
          },
        },
      });

      setGlobalLoading(false);
      navigate(0);
      toast.success("Você foi vinculado a PAC!");
    } catch (err) {
      setGlobalLoading(false);
      errorHandler(err);
    }
  }

  async function onSubmitUnbindPacOperator(id: number) {
    setGlobalLoading(true);
    try {
      await runUnbindPacOperator({
        variables: {
          id,
        },
      });

      setGlobalLoading(false);
      navigate(0);
      toast.success("Operador desvinculado da PAC!");
    } catch (err) {
      setGlobalLoading(false);
      errorHandler(err);
    }
  }

  async function onSubmitUnbindPacConstructionCompany(id: number) {
    setGlobalLoading(true);
    try {
      await runUnbindPacConstructionCompany({
        variables: {
          id,
        },
      });

      setGlobalLoading(false);
      navigate(0);
      toast.success("Construtor(a) desvinculada!");
    } catch (err) {
      setGlobalLoading(false);
      errorHandler(err);
    }
  }

  async function onSubmitNewHistory(data: any) {
    if (data.content < 3)
      return toast.warn(
        "O comentário/observação deve ter pelo menos 3 caracteres"
      );

    setGlobalLoading(true);
    try {
      const { data: dataNewHistory } = await runCreatePacHistory({
        variables: {
          createHistoryInput: {
            pacId: Number(id),
            content: data.content,
          },
        },
      });

      if (dataNewHistory)
        setHistories([dataNewHistory.createPacHistory, ...histories]);

      toast.success("Pac atualizada com sucesso!");
    } catch (err) {
      errorHandler(err);
    }
    setIsDialogNewHistoryOpen(false);
    setGlobalLoading(false);
  }

  async function onSubmit(newData: any) {
    for (let key of Object.keys(newData)) {
      if (typeof newData[key] === "object") {
        newData[key] = JSON.stringify(newData[key]);
        //@ts-ignore
        if (newData[key] === JSON.stringify(data[key])) delete newData[key];
      } else if (
        //@ts-ignore
        newData[key] === data[key] ||
        //@ts-ignore
        (newData[key] === "" && newData[key] === data[key])
      ) {
        delete newData[key];
      } else if (newData[key] === undefined || newData[key] === null)
        delete newData[key];
    }

    if (newData.CPFClient && !verifyCPF(newData.CPFClient))
      return toast.error("CPF do Cliente Inválido!");
    if (newData.CPFConjuge && !verifyCPF(newData.CPFConjuge))
      return toast.error("CPF do Cônjuge Inválido!");

    delete newData["histories"];

    newData["internalInfos"] = newData["internalInfos"] || "{}";
    newData["infos"] = newData["infos"] || "{}";

    newData["id"] = Number(id);

    let content = newData["content"] || "";
    delete newData["content"];

    setGlobalLoading(true);
    let updatePacInput: Variables["updatePacInput"] = { id: Number(id) || 0 };
    try {
      if (tab === 0) {
        if (newData["modality"]) delete newData["modality"];
        delete newData["internalInfos"];

        updatePacInput = {
          ...newData,
          ...updatePacInput,
        };
        Validation.validateSync({ ...newData, content });
      } else {
        Validation.validateSync({
          id: Number(id),
          modality: newData["modality"],
          internalInfos: newData["internalInfos"],
        });
        updatePacInput = {
          modality: newData["modality"] as string,
          internalInfos: newData["internalInfos"],
          ...updatePacInput,
        };

        content = false;
      }

      await runUpdatePac({
        variables: {
          updatePacInput,
        },
      });

      if (content !== false) {
        const { data: dataNewHistory } = await runCreatePacHistory({
          variables: {
            createHistoryInput: {
              pacId: Number(id),
              content,
            },
          },
        });

        if (dataNewHistory)
          setHistories([dataNewHistory.createPacHistory, ...histories]);
      }

      toast.success("Pac atualizada com sucesso!");

      if (tab === 0) setEdit(false);
      else setEditInternal(false);
    } catch (err: any) {
      toast.error(err?.message);
    }
    setGlobalLoading(false);
  }

  if (error) {
    toast.error("Pac não encontrada!", { toastId: 298 });
    return <Navigate to="/pacs" />;
  }

  if (loading)
    return (
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/pacs">
            Pacs
          </Link>
          <Typography color="text.primary">#{id}</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(_, e) => setTab(e)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              label="Informações"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
              icon={<InfoIcon fontSize="medium" />}
              value={0}
            />
            <Tab
              label="Documentos"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
              icon={<FolderIcon fontSize="medium" />}
              value={1}
            />
            {permission([Role.ADMIN, Role.CORRESPONDENTE], roles) && (
              <Tab
                label="Área Interna"
                id="simple-tab-2"
                aria-controls="simple-tabpanel-2"
                icon={<LockIcon fontSize="medium" />}
                value={2}
              />
            )}
          </Tabs>
        </Box>
        {/*Infos*/}
        <Box
          role="tabpanel"
          hidden={tab !== 0}
          id="simple-tabpanel-0"
          aria-labelledby={`simple-tab-0`}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
            {permission([Role.ADMIN, Role.CORRESPONDENTE], roles) &&
              (edit ? (
                <>
                  <IconButton type="submit">
                    <SaveIcon color="success" />
                  </IconButton>
                  <IconButton type="button" onClick={() => setEdit(false)}>
                    <CancelIcon color="inherit" />
                  </IconButton>
                </>
              ) : (
                <IconButton onClick={() => setEdit(true)}>
                  <EditIcon color="warning" />
                </IconButton>
              ))}
            {permission([Role.ADMIN], roles) && (
              <IconButton onClick={handleDelete}>
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </Box>
          {permission([Role.ADMIN], roles) && data.operator && (
            <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
              <Button
                color="error"
                variant="outlined"
                onClick={() => {
                  window.confirm(
                    "Você tem certeza que deseja desvincular o operador da pac ?"
                  ) && onSubmitUnbindPacOperator(Number(id));
                }}
              >
                DESVINCULAR OPERADOR
              </Button>
            </Box>
          )}
          {permission([Role.CORRESPONDENTE], roles) && !data.operator && (
            <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
              <Button
                color="warning"
                variant="outlined"
                onClick={() => {
                  window.confirm(
                    "Você tem certeza que deseja se vincular a pac ?"
                  ) && onSubmitBindPacOperator(Number(id));
                }}
              >
                VINCULAR-SE A PAC
              </Button>
            </Box>
          )}
          {permission([Role.ADMIN, Role.CORRESPONDENTE], roles) &&
            !data.constructionCompany && (
              <Box
                sx={{ display: "flex", justifyContent: "center", margin: 1 }}
              >
                <Button
                  color="success"
                  variant="outlined"
                  onClick={() => {
                    setIsDialogConstructionCompanyOpen(true);
                  }}
                >
                  VINCULAR CONSTRUTORA
                </Button>
              </Box>
            )}
          {permission([Role.ADMIN, Role.CORRESPONDENTE], roles) &&
            data.constructionCompany && (
              <Box
                sx={{ display: "flex", justifyContent: "center", margin: 1 }}
              >
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    window.confirm(
                      "Você tem certeza que deseja desvincular a construtora da PAC?"
                    ) && onSubmitUnbindPacConstructionCompany(Number(id));
                  }}
                >
                  DESVINCULAR CONSTRUTORA
                </Button>
              </Box>
            )}
          <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
            <Button
              variant="outlined"
              onClick={() => setIsDialogNewHistoryOpen(true)}
            >
              ADICIONAR COMENTÁRIO/OBSERVAÇÃO
            </Button>
          </Box>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                sx={{ margin: 1 }}
                label="Nome do Cliente"
                fullWidth
                disabled={!edit}
                required
                {...register("nameClient")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CPFInput
                required
                register={register}
                name="CPFClient"
                disabled={!edit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                sx={{ margin: 1 }}
                label="Email"
                type="email"
                fullWidth
                disabled={!edit}
                {...register("emailClient")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <PhoneInput
                register={register}
                name="phoneClient"
                disabled={!edit}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                sx={{ margin: 1 }}
                label="Cônjuge"
                fullWidth
                disabled={!edit}
                {...register("nameConjuge")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CPFInput
                name="CPFConjuge"
                label="CPF Cônjuge"
                register={register}
                disabled={!edit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                sx={{ margin: 1 }}
                label="Email do Conjugê"
                type="email"
                fullWidth
                disabled={!edit}
                {...register("emailConj")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <PhoneInput
                register={register}
                name="phoneConj"
                label="Telefone Conjugê"
                disabled={!edit}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                sx={{ margin: 1 }}
                label="Endereço de residência"
                disabled={!edit}
                fullWidth
                {...register("address")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CEPInput register={register} name="CEP" disabled={!edit} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth sx={{ margin: 1 }}>
                <InputLabel id="type-label">Tipo</InputLabel>
                <Select
                  labelId="type-label"
                  label="Tipo"
                  fullWidth
                  defaultValue={initialType}
                  disabled={!edit}
                  required
                  {...register("type")}
                >
                  {Object.values(Type).map((t) => (
                    <MenuItem key={`${new Date().getTime()}-${t}`} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth sx={{ margin: 1 }}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  fullWidth
                  disabled={!edit}
                  defaultValue={initialStatus}
                  required
                  {...register("status")}
                >
                  {Object.values(Status)
                    .sort()
                    .map((t) => (
                      <MenuItem key={`${new Date().getTime()}-${t}`} value={t}>
                        {t}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                sx={{ margin: 1, cursor: "not-allowed" }}
                label="Imobiliária"
                fullWidth
                disabled={true}
                {...register("user.realEstate.name")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                sx={{ margin: 1, cursor: "not-allowed" }}
                label="Equipe"
                fullWidth
                disabled={true}
                {...register("user.TeamUsers[0].team.name")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Tooltip
                title={
                  data?.user?.TeamUsers?.map((t) => t?.team?.owner?.name).join(
                    " / "
                  ) || ""
                }
                arrow
              >
                <TextField
                  sx={{ margin: 1, cursor: "not-allowed" }}
                  label="Gerente"
                  fullWidth
                  disabled={true}
                  value={
                    data?.user?.TeamUsers?.map(
                      (t) => t?.team?.owner?.name
                    ).join(" / ") || ""
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                sx={{ margin: 1, cursor: "not-allowed" }}
                label="Responsável"
                fullWidth
                disabled={true}
                {...register("user.name")}
              />
            </Grid>

            <Grid item xs={12}>
              <Tooltip title={data.product || ""} arrow>
                <TextField
                  fullWidth
                  sx={{ margin: 1 }}
                  label="Produto"
                  disabled={!edit}
                  required
                  {...register("product")}
                />
              </Tooltip>
            </Grid>
            {data?.constructionCompany && (
              <Grid item xs={12}>
                <Tooltip title={data?.constructionCompany?.name || ""} arrow>
                  <TextField
                    fullWidth
                    sx={{ margin: 1 }}
                    label="Construtor(a) Vinculado(a)"
                    disabled={true}
                    required
                    {...register("constructionCompany.name")}
                  />
                </Tooltip>
              </Grid>
            )}
            {data?.operator && (
              <Grid item xs={12}>
                <Tooltip title={data?.operator?.name || ""} arrow>
                  <TextField
                    fullWidth
                    sx={{ margin: 1 }}
                    label="Operador Vinculado/Responsável"
                    disabled={true}
                    required
                    {...register("operator.name")}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
          {edit && (
            <Grid container spacing={2} padding={1}>
              <Grid item xs={12}>
                <TextField
                  sx={{ margin: 1 }}
                  multiline
                  label="Descrição da alteração"
                  {...register("content")}
                  fullWidth
                  rows={4}
                  required
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} padding={1}>
            <Grid padding={2} container>
              <Grid item xs={12} marginTop={2}>
                <Typography
                  variant="h6"
                  marginBottom={1}
                  sx={{ display: "flex" }}
                >
                  <InfoIcon fontSize="large" sx={{ marginRight: 1 }} />
                  Informações Extras
                </Typography>
              </Grid>

              {Object.keys(data?.infos || []).length <= 0 &&
                IDsInfos.length <= 0 && (
                  <Grid item xs={12} marginTop={2}>
                    <Typography
                      variant="subtitle1"
                      marginBottom={1}
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      Sem Informações
                    </Typography>
                  </Grid>
                )}

              {Object.keys(data?.infos || []).map((k) => (
                <Grid
                  key={`infos-${new Date().getTime()}-${k}`}
                  item
                  xs={12}
                  padding={1}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor={`infos.${k}`}>{k}</InputLabel>
                    <OutlinedInput
                      label={k}
                      fullWidth
                      color="primary"
                      required
                      disabled={!edit}
                      {...register(`infos.${k}`)}
                      endAdornment={
                        edit && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => deleteInfo(k)}
                              edge="end"
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
              {IDsInfos.map((idI) => (
                <Grid
                  key={`infos-${new Date().getTime()}-${idI}`}
                  item
                  xs={12}
                  padding={1}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor={`infos.${idI}`}>{idI}</InputLabel>
                    <OutlinedInput
                      defaultValue=""
                      label={idI}
                      fullWidth
                      color="primary"
                      disabled={!edit}
                      {...register(`infos.${idI}`)}
                      endAdornment={
                        edit && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setIDsInfos(IDsInfos.filter((k) => k !== idI))
                              }
                              edge="end"
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                paddingTop={2}
                paddingRight={1}
              >
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={() => setIsDialogInfosOpen(true)}
                  disabled={!edit}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} padding={1}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ display: "flex", alignItens: "center" }}>
                    <HistoryIcon sx={{ marginRight: 1 }} /> Histórico
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {histories?.map((h) => (
                    <History key={`${new Date().getTime()}-${h.id}`} data={h} />
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>

        {/*Docs*/}
        <Box
          role="tabpanel"
          hidden={tab !== 1}
          id="simple-tabpanel-1"
          aria-labelledby={`simple-tab-1`}
        >
          <Grid
            container
            spacing={2}
            padding={1}
            margin={1}
            sx={{
              background: (theme) => theme.palette.primary.main,
              borderRadius: 2,
              width: "100%",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FolderIcon fontSize="large" sx={{ marginRight: 1 }} />{" "}
                <strong>Documentos</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {IDsDocs.map((idDoc) => (
                <Accordion key={idDoc}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: "flex", alignItens: "center" }}>
                      <AssignmentInd sx={{ marginRight: 1 }} /> {idDoc}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DocumentsTable
                      setDocuments={setDocuments}
                      idDoc={idDoc}
                      documents={documents}
                      permission={permission(
                        [Role.ADMIN, Role.CORRESPONDENTE],
                        roles
                      )}
                    />
                    {idDoc.toUpperCase() !== "IMOBZI" && (
                      <FileUpload name={idDoc} pacId={Number(id)} />
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              paddingTop={2}
              paddingRight={1}
            >
              <Fab
                color="secondary"
                aria-label="add"
                onClick={() => setIsDialogDocOpen(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Box>
        {/*Área Interna*/}
        <Box
          role="tabpanel"
          hidden={tab !== 2}
          id="simple-tabpanel-2"
          aria-labelledby={`simple-tab-2`}
        >
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}
          >
            {permission([Role.ADMIN, Role.CORRESPONDENTE], roles) &&
              (editInternal ? (
                <>
                  <IconButton type="submit">
                    <SaveIcon color="success" />
                  </IconButton>
                  <IconButton
                    type="button"
                    onClick={() => setEditInternal(false)}
                  >
                    <CancelIcon color="inherit" />
                  </IconButton>
                </>
              ) : (
                <IconButton onClick={() => setEditInternal(true)}>
                  <EditIcon color="warning" />
                </IconButton>
              ))}
            {permission([Role.ADMIN], roles) && (
              <IconButton onClick={handleDelete}>
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </Box>
          {permission([Role.ADMIN, Role.CORRESPONDENTE], roles) && (
            <Grid container spacing={2} padding={1}>
              <Grid padding={2} container>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Modalidade"
                    disabled={!editInternal}
                    {...register("modality")}
                  />
                </Grid>

                <Grid item xs={12} marginTop={2}>
                  <Typography
                    variant="h6"
                    marginBottom={1}
                    sx={{ display: "flex" }}
                  >
                    <InfoIcon fontSize="large" sx={{ marginRight: 1 }} />
                    Informações Internas - Extras
                  </Typography>
                </Grid>

                {Object.keys(data?.internalInfos || []).length <= 0 &&
                  IDsInternalInfos.length <= 0 && (
                    <Grid item xs={12} marginTop={2}>
                      <Typography
                        variant="subtitle1"
                        marginBottom={1}
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        Sem Informações
                      </Typography>
                    </Grid>
                  )}

                {Object.keys(data?.internalInfos || []).map((k) => (
                  <Grid
                    key={`${new Date().getTime()}-${k}`}
                    item
                    xs={12}
                    padding={1}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor={`internalInfos.${k}`}>
                        {k}
                      </InputLabel>
                      <OutlinedInput
                        label={k}
                        fullWidth
                        color="primary"
                        required
                        disabled={!editInternal}
                        {...register(`internalInfos.${k}`)}
                        endAdornment={
                          editInternal && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => deleteInternalInfo(k)}
                                edge="end"
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      />
                    </FormControl>
                  </Grid>
                ))}
                {IDsInternalInfos.map((idI) => (
                  <Grid
                    key={`${new Date().getTime()}-${idI}`}
                    item
                    xs={12}
                    padding={1}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor={`internalInfos.${idI}`}>
                        {idI}
                      </InputLabel>
                      <OutlinedInput
                        defaultValue=""
                        label={idI}
                        fullWidth
                        color="primary"
                        disabled={!editInternal}
                        {...register(`internalInfos.${idI}`)}
                        endAdornment={
                          editInternal && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setIDsInternalInfos(
                                    IDsInternalInfos.filter((k) => k !== idI)
                                  )
                                }
                                edge="end"
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      />
                    </FormControl>
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="flex-end"
                  paddingTop={2}
                  paddingRight={1}
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => setIsDialogOpen(true)}
                    disabled={!editInternal}
                  >
                    <AddIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>

        <Dialog
          open={dialogInfosOpen}
          onClose={() => setIsDialogInfosOpen(false)}
        >
          <DialogTitle>Identificador da Informação</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Digite o "identificador da informação". Exemplo: "Valor Avaliado"
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Identificador"
              fullWidth
              variant="standard"
              inputRef={inputInfosDialog}
              inputProps={{ style: { textTransform: "uppercase" } }}
              helperText={"Você deve digitar pelo menos 3 caracteres."}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogInfosOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleDialogInfosSubmit}>Cadastrar</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogOpen} onClose={() => setIsDialogOpen(false)}>
          <DialogTitle>Identificador da Informação</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Digite o "identificador da informação". Exemplo: "Valor Avaliado"
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Identificador"
              fullWidth
              variant="standard"
              inputRef={inputDialog}
              inputProps={{ style: { textTransform: "uppercase" } }}
              helperText={"Você deve digitar pelo menos 3 caracteres."}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>Cancelar</Button>
            <Button onClick={handleDialogSubmit}>Cadastrar</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogDocOpen} onClose={() => setIsDialogDocOpen(false)}>
          <DialogTitle>Identificador do Documento</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Digite o "identificador do documento". Exemplo: "CARTA DE
              CONCESSÃO"
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Identificador"
              fullWidth
              variant="standard"
              inputRef={inputDialogDoc}
              inputProps={{ style: { textTransform: "uppercase" } }}
              helperText={"Você deve digitar pelo menos 3 caracteres."}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogDocOpen(false)}>Cancelar</Button>
            <Button onClick={handleDialogDocSubmit}>Cadastrar</Button>
          </DialogActions>
        </Dialog>
      </form>

      <Dialog
        open={dialogNewHistoryOpen}
        fullWidth
        onClose={() => setIsDialogNewHistoryOpen(false)}
      >
        <form onSubmit={handleSubmitNewHistory(onSubmitNewHistory)}>
          <DialogTitle textAlign="center">
            Adicionar Comentário/Observação
          </DialogTitle>
          <DialogContent>
            <TextField
              multiline
              placeholder="DIGITE AQUI"
              {...registerNewHistory("content")}
              fullWidth
              rows={4}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogNewHistoryOpen(false)}>
              Cancelar
            </Button>
            <Button type="submit">Registrar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <DialogSelectConstructionCompany
        open={dialogConstructionCompanyOpen}
        setIsOpen={setIsDialogConstructionCompanyOpen}
        setLoading={setGlobalLoading}
        pacId={Number(id)}
      />

      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={globalLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </Container>
  );
};

export default Pac;
